import Vue from 'vue'
import { BootstrapVue } from 'bootstrap-vue'

import App from '@/App.vue'
import router from '@/router'
import store from '@/store'

// Import Bootstrap an BootstrapVue CSS files
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/assets/scss/app.scss'
// //import fontawesome
import '@/assets/font-awesome/css/duotone.min.css'
import '@/assets/font-awesome/css/fontawesome.min.css'
import '@/assets/font-awesome/css/solid.min.css'

//import vue moment
import moment from 'moment'
Vue.prototype.moment = moment


import '@/assets/font-awesome/js/fontawesome.min.js'
import '@/assets/font-awesome/js/solid.min.js'
import '@/assets/font-awesome/js/duotone.min.js'
// Import multi-language
import i18n from './i18n'
//import native socket
import VueNativeSock from "vue-native-websocket";
// import donut chart
import Donut from 'vue-css-donut-chart';
import 'vue-css-donut-chart/dist/vcdonut.css';
//import vue toast
import VueToastr from 'vue-toastr';
//import animated css
import 'animate.css';
// Active BootstrapVue
Vue.use(BootstrapVue)
// Initialize Vue App
Vue.config.productionTip = false
//vue cookie
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)
//vue v-copy
import Clipboard from 'v-clipboard'
Vue.use(Clipboard)
//lazy load img
import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload)
Vue.use(VueLazyload, {
    preLoad: 1.3,
    error: './assets/images/lazyload/error.png',
    loading: './assets/images/lazyload/loading.gif',
    attempt: 1
})
// active donut chart
Vue.use(Donut);
//active vue toast
Vue.use(VueToastr, {
    defaultTimeout: 1000,
    progressbar: true,
    defaultProgressBarValue: 2000,
    defaultPosition: "toast-top-right",
    defaultCloseOnHover: false,
    defaultClassNames: ["animate__animated", "fadeInDown"],
});
//active native socket
Vue.use(VueNativeSock, "wss://trade-socket.chidetest.com", {
    connectManually: true,
    reconnection: false, // (Boolean) whether to reconnect automatically (false)
    reconnectionAttempts: 1, // (Number) number of reconnection attempts before giving up (Infinity),
    // reconnectionDelay: 2000 // (Number) how long to initially wait before attempting a new (1000)
});
//sentry
///sound
const createjs = window.createjs; // Get the createjs instance from window object
Vue.prototype.createjs = createjs;
//tippy
import VueTippy, { TippyComponent } from "vue-tippy";
Vue.use(VueTippy, {
    directive: "tippy", // => v-tippy
    flipDuration: 0,
    popperOptions: {
        modifiers: {
            preventOverflow: {
                enabled: false
            }
        }
    }
});
Vue.component("tippy", TippyComponent);
//vue captcha v3
import { VueReCaptcha } from 'vue-recaptcha-v3'
Vue.use(VueReCaptcha, {
    siteKey: process.env.VUE_APP_CAPTCHAV3,
    loaderOptions: {
        useRecaptchaNet: true,
        autoHideBadge: true
    }
})

Vue.mixin({
    methods: {
        genarateCaptChaV3: async function (nameCaptCha) {
            await this.$recaptchaLoaded()
            var token = await this.$recaptcha(nameCaptCha)
            return token;
        }
    },
})
//
new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')