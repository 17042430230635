<template>
  <div class="text-danger">
    <!-- Create -->
    <div>
      <h5>Create Room</h5>
      <div class="form-group">
        <label>Room Name</label>
        <input
          type="text"
          v-model="Room.name"
          placeholder="tên phòng"
        />
      </div>
      <div class="form-group">
        <label>Password</label>
        <input
          type="password"
          v-model="Room.password"
          placeholder="mật khẩu"
        />
      </div>
      <b-button
        variant="primary"
        class="mx-1 my-1"
        @click="CreateNewRoom()"
      >
        Create
      </b-button>
      <b-button
        variant="danger"
        class="mx-1 my-1"
        @click="Close()"
      >
        Cancel
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["onClose" ,"onSubmit"],
  data() {
    return {
      Room: {
        name: "",
        password: "",
        locale: "VI",
        symbolId: "BTCUSDT",
      },
    };
  },
  computed: {},
  methods: {
      CreateNewRoom : function(){
          this.onSubmit(this.Room);
      },
      Close : function(){
          this.onClose();
      }
  },
  mounted() {},
  watch: {},
};
</script>
