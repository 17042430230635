const axios = require("../config/axios.config.js").default;
export default {
    namespaced: true,
    state: () => ({
        history: {
            data: [],
            total: 0
        },
        price: [],
        coin_address: { QrCode: "", address: "" },
        action_list: []
    }),

    getters: {
        History: (state) => { return state.history },
        Price: (state) => { return state.price },
        CoinAddress: (state) => { return state.coin_address },
        ActionList: (state) => { return state.action_list }
    },

    actions: {
        req_getPrice: async function ({ commit }, input) {
            axios.get("wallet/price", input).then(function (data) {
                if (data) {
                    commit("SET_WALLET_HISTORY", data.history);
                    commit("SET_PRICE_COIN", data.symbol);
                }
            });
        },
        req_getAddress: async function ({ commit }, input) {
            axios.get("wallet/address?coin=" + input).then(function (data) {
                if (data) {
                    commit("SET_COIN_ADDRESS", data)
                }
            })
        },
        req_getListAction: async function ({ commit }) {
            axios.get("wallet/action").then(function (data) {
                if (data) {
                    commit("SET_LIST_ACTION", data);
                }
            })
        },
        req_postWithdraw: async function ({ dispatch, commit }, input) {
            axios.post("wallet/withdraw", input).then(function (data) {
                commit("userinfo/setBalace", {
                    main: true,
                    balance: data.MainBalance,
                },{ root: true });
                dispatch("req_getPrice");
            })
        },
        req_postTransfer: async function ({ dispatch, commit }, input) {
            axios.post("wallet/transfer", input).then(function (data) {
                commit("userinfo/setBalace", {
                    main: true,
                    balance: data.MainBalance,
                },{ root: true });
                dispatch("req_getPrice");
            })
        }
    },

    mutations: {
        SET_WALLET_HISTORY: function (state, data) {
            state.history.data = data.data;
            state.history.total = data.last_page;
        },
        SET_PRICE_COIN: function (state, data) {
            state.price = data;
        },
        SET_COIN_ADDRESS: function (state, data) {
            state.coin_address = data;
        },
        SET_LIST_ACTION: function (state, data) {
            state.action_list = data;
        }

    }
};
