<template>
  <form @submit.prevent="Unlock()">
    <h5 style="padding-top: 10px;">Unlock room</h5>
    <div class="form-group">
      <label>Room Name</label>
      <input type="text" v-model="Room.name" required />
    </div>
    <div class="form-group">
      <label>Password</label>
      <input type="password" v-model="Room.password" />
    </div>
    <b-button variant="primary" class="mx-1 my-1" type="submit">Join</b-button>
    <b-button
      variant="danger"
      class="mx-1 my-1"
      @click="Close()"
    >
      Cancel
    </b-button>
  </form>
</template>

<script>
export default {
  props: ["room","onUnlock","onClose"],
  data(){
    return {
      Room : {
        name:"",
        password:"",
      }
    }
  },
  computed: {

  },
  methods: {
    Open: function(room){
      this.Room.name = room.name;
      this.Room.password = "";
    },
    Close:function(){
      this.Room = {
        name:"",
        password:"",
      };
      this.onClose();
    },
    Unlock:function(){
      this.onUnlock(this.Room);
    }
  },
  mounted(){
  },
  watch:{

  } 
}
</script>