/* eslint-disable no-unused-vars */
<template>
	<keep-alive>
		<div class="privateTransaction p-2">
			<div class="d-lg-flex d-md-grid">
				<!-- MAIN PANEL -->
				<div class="sidePanel">
					<!-- PREDICT VALUE -->
					<div class="form-group d-block d-lg-none mb-0">
						<label for="" class="d-none d-lg-block">Lorem</label>
						<div class="predictValue text-center text-lg-left">
							<span class="currentRatio"
								>{{ transaction.currentRatio * 100 }}%</span
							>
							<span class="sign">{{ predictValue >= 0 ? '+' : '-' }}</span>
							<CurrencyFormat
								class="predictValue d-inline"
								:value="Math.abs(predictValue)"
							/>
						</div>
					</div>
					<!-- END PREDICT VALUE -->

					<!-- ADD VALUE -->
					<div class="form-group mb-1 mb-lg-2">
						<label for="value" class="d-none d-lg-block">{{
							$t('transaction.value')
						}}</label>
						<div class="d-flex">
							<b-button
								class="px-3 mr-2 d-block d-lg-none flex-30p"
								@click="
									transaction.value =
										transaction.value >= 5 ? transaction.value - 5 : 0
								"
								>-</b-button
							>
							<div class="transactionValue rounded flex-fill flex-40p">
								<input
									type="number"
									class="w-100"
									v-model.number="transaction.value"
								/>
								<b-button
									variant="secondary"
									size="sm"
									pill
									class="clearValue bg-white"
									@click="transaction.value = 0"
								>
									<img
										src="@/assets/images/icons/close.svg"
										alt="Clear value"
									/>
								</b-button>
							</div>
							<b-button
								class="px-3 ml-2 d-block d-lg-none flex-30p"
								@click="transaction.value += 5"
								>+</b-button
							>
						</div>
						<div
							class="addValue flex-wrap justify-content-between d-none d-lg-flex"
						>
							<b-button
								class="sampleValue"
								variant="outline-primary"
								@click="
									transaction.value = parseInt(user.accounts[0].balance * 0.01)
								"
								>1%</b-button
							>
							<b-button
								class="sampleValue"
								variant="outline-primary"
								@click="
									transaction.value = parseInt(user.accounts[0].balance * 0.02)
								"
								>2%</b-button
							>
							<b-button
								class="sampleValue"
								variant="outline-primary"
								@click="transaction.value += 10"
								>+10</b-button
							>
							<b-button
								class="sampleValue"
								variant="outline-primary"
								@click="transaction.value += 20"
								>+20</b-button
							>
							<b-button
								class="sampleValue"
								variant="outline-primary"
								@click="transaction.value += 50"
								>+50</b-button
							>
							<b-button
								class="sampleValue"
								variant="outline-primary"
								@click="transaction.value = parseInt(user.accounts[0].balance)"
								>All</b-button
							>
						</div>
					</div>
					<!-- END ADD VALUE -->

					<!-- PREDICT VALUE -->
					<div class="form-group d-none d-lg-block bg-form">
						<label for="" class="d-none d-lg-block">Traders sentiments</label>
						<RatioBar :value="0.3" />
					</div>
					<div class="form-group d-none d-lg-block bg-form2">
						<label for="" class="d-none d-lg-block">Profit</label>
						<div class="predictValue text-center">
							<span class="currentRatio"
								>{{ transaction.currentRatio * 100 }}%</span
							>
							<span class="sign">{{ predictValue >= 0 ? '+' : '-' }}</span>
							<CurrencyFormat
								class="predictValue d-inline"
								:value="Math.abs(predictValue)"
							/>
						</div>
					</div>
					<!-- END PREDICT VALUE -->

					<!-- RATIO BAR -->

					<!-- END RATIO BAR -->

					<!-- TRANSACTION ACTION -->
					<div class="form-group transactionAction mb-0 d-flex d-lg-block">
						<div class="py-1 pr-1 py-lg-0 pr-lg-0 flex-35p">
							<b-button
								variant="success"
								size="lg"
								class="w-100 action bet"
								@click="!disabledBTN ? betBuy() : ''"
								:disabled="disabledBTN"
							>
								{{
									amountBet.buy > 0
										? amountBet.buy
										: $t('transaction.increase')
								}}<img
									class="d-none d-lg-inline"
									src="@/assets/images/transaction/increase.png"
								/>
							</b-button>
						</div>
						<div class="info w-100 p-1 flex-30p">
							<div>
								<small>{{
									$t(
										transaction.round == 'order'
											? 'transaction.orderResult'
											: 'transaction.waitResult'
									)
								}}</small>
							</div>
							<div>
								<strong :key="transaction.timeRemain"
									>{{
										transaction.timeRemain > 30
											? 60 - transaction.timeRemain
											: 30 - transaction.timeRemain
									}}s</strong
								>
							</div>
						</div>
						<div class="py-1 pl-1 py-lg-0 pl-lg-0 flex-35p">
							<b-button
								variant="danger"
								size="lg"
								class="w-100 action bet"
								@click="!disabledBTN ? betSell() : ''"
								:disabled="disabledBTN"
							>
								{{
									amountBet.sell > 0
										? amountBet.sell
										: $t('transaction.decrease')
								}}<img
									class="d-none d-lg-inline"
									src="@/assets/images/transaction/decrease.png"
								/>
							</b-button>
						</div>
					</div>
					<!-- END TRANSACTION ACTION -->
				</div>
				<div class="mainPanel">
					<!-- TRADING CHART WRAPPER -->
					<div class="tradingChartWrapper" ref="tradingChartWrapper">
						<!-- LABEL -->

						<!-- <div class="tradingChartLabel rounded">BTC/USDT</div> -->
						<b-dropdown class="rounded customer-drop" variant="outline-primary">
							<template #button-content>
								<div
									class="d-flex justify-content-center align-items-center align-self-center"
								>
									<div class="coinImage">
										<img
											:src="
												require('@/assets/images/transaction/' +
													currentCoin.image)
											"
											width="35"
											alt=""
											class="imageLandScape"
										/>
									</div>
									<div class="coinName ml-1">{{ currentCoin.name }}</div>
								</div>
							</template>
							<b-dropdown-item-button
								v-for="coin in coinListAv"
								:key="`changeAccount-${coin.id}`"
								@click="changeCurrency(coin.id)"
								class="customer-items"
							>
								<div class="customer-items">
									<div class="coinImage">
										<img
											:src="
												require('@/assets/images/transaction/' + coin.image)
											"
											width="35"
											alt=""
											class="imageLandScape"
										/>
									</div>
									<div class="coinName ml-1">{{ coin.name }}</div>
								</div>
							</b-dropdown-item-button>
						</b-dropdown>
						<!-- END LABEL -->

						<div class="notify-win" v-if="show_popup_profit">
							<div class="body-noti p-2">
								<transition
									name="custom-classes-transition"
									enter-active-class="animate__animated  animate__bounceIn"
									leave-active-class="animate__animated  animate__bounceOut"
								>
									<div class="content">
										<p :class="win_amount > 0 ? 'buy' : 'sell'">
											{{ win_amount > 0 ? 'CONGRATULATIONS!' : 'LOSE!' }}
										</p>
										<p :class="win_amount > 0 ? 'amount-win' : 'amount-lose'">
											{{
												win_amount
													.toFixed(2)
													.replace(/\d(?=(\d{3})+\.)/g, '$&,')
											}}
											<i
												:class="
													win_amount > 0
														? 'fad  fa-arrow-up'
														: 'fad  fa-arrow-down'
												"
											></i>
										</p>
									</div>
								</transition>
							</div>
						</div>

						<!-- CHART -->
						<!-- For more information about trading-vue: https://github.com/tvjsx/trading-vue-js -->
						<trading-vue
							:data="this.$data"
							:overlays="overlays"
							:titleTxt="''"
							:chart-config="config"
							:width="tradingChart.width"
							:height="tradingChart.height"
							colorBack="#111315"
							colorCross="#f3d91f"
							colorGrid="rgb(255,255,255,.3)"
							colorVolUp="#23a77657"
							colorVolDw="#e5415047"
							:key="updataC"
							ref="tradingChart"
							class="tradingChart"
							v-if="showtrade"
						></trading-vue>
						<!-- END CHART -->
					</div>
					<!-- END TRADING CHART WRAPPER -->

					<!-- SUMMARY -->
					<b-tabs class="summary">
						<b-tab title="Indicators" v-if="false">
							<div class="rateChartList d-flex">
								<div
									class="rateChartWrapper secondary d-flex align-items-center"
								>
									<RateChart
										:value="0.2"
										title="Oscillators"
										:sell="4"
										:neutral="5"
										:buy="0"
									/>
								</div>
								<div class="rateChartWrapper primary d-flex align-items-center">
									<RateChart
										:value="0.7"
										title="Summary"
										:sell="4"
										:neutral="5"
										:buy="0"
									/>
								</div>
								<div
									class="rateChartWrapper secondary d-flex align-items-center"
								>
									<RateChart
										:value="0.3"
										title="Moving Averages"
										:sell="4"
										:neutral="5"
										:buy="0"
									/>
								</div>
							</div>
						</b-tab>
						<b-tab title="Last Results" class="lastResult" active>
							<div class="info">
								<div class="increase value">
									<span class="label">{{ $t('transaction.increase') }}</span>
									{{ historyNumberIncrease }}
								</div>
								<div class="decrease value">
									<span class="label">{{ $t('transaction.decrease') }}</span>
									{{ historyNumberDecrease }}
								</div>
							</div>
							<div class="dotGraph my-2">
								<div class="dotGroup d-none d-lg-flex">
									<div
										:class="`dot ${
											value == 0
												? 'increase'
												: value == 1
												? 'decrease'
												: value == 2
												? 'draw'
												: ''
										}`"
										v-for="(value, index) in history.slice(0, 20)"
										:key="`dot-${index + 100}`"
									></div>
									<div
										class="dot"
										v-for="(value, index) in 20 - history.slice(0, 20).length"
										:key="`dot-${index + 80}`"
									></div>
								</div>
								<div class="dotGroup d-none d-md-flex">
									<div
										:class="`dot ${
											value == 0
												? 'increase'
												: value == 1
												? 'decrease'
												: value == 2
												? 'draw'
												: ''
										}`"
										v-for="(value, index) in history.slice(20, 40)"
										:key="`dot-${index + 60}`"
									></div>
									<div
										class="dot"
										v-for="(value, index) in 20 - history.slice(20, 40).length"
										:key="`dot-${index + 40}`"
									></div>
								</div>
								<div class="dotGroup d-flex">
									<div
										:class="`dot ${
											value == 0
												? 'increase'
												: value == 1
												? 'decrease'
												: value == 2
												? 'draw'
												: ''
										}`"
										v-for="(value, index) in history.slice(40, 60)"
										:key="`dot-${(index += 20)}`"
									></div>
									<div
										class="dot"
										v-for="(value, index) in 20 - history.slice(40, 60).length"
										:key="`dot-${index}`"
									></div>
								</div>
								<div class="dotGroup d-flex">
									<div
										:class="`dot ${
											value == 0
												? 'increase'
												: value == 1
												? 'decrease'
												: value == 2
												? 'draw'
												: ''
										}`"
										v-for="(value, index) in history.slice(60, 80)"
										:key="`dot-${(index += 120)}`"
									></div>
									<div
										class="dot"
										v-for="(value, index) in 20 - history.slice(60, 80).length"
										:key="`dot-${(index += 140)}`"
									></div>
								</div>
								<div class="dotGroup d-flex">
									<div
										:class="`dot ${
											value == 0
												? 'increase'
												: value == 1
												? 'decrease'
												: value == 2
												? 'draw'
												: ''
										}`"
										v-for="(value, index) in history.slice(80, 100)"
										:key="`dot-${(index += 160)}`"
									></div>
									<div
										class="dot"
										v-for="(value, index) in 20 - history.slice(80, 100).length"
										:key="`dot-${(index += 180)}`"
									></div>
								</div>
							</div>
						</b-tab>
					</b-tabs>
					<!-- END SUMMARY -->
				</div>
				<div class="sidePanel right">
					<div class="tableWrapper table-responsive bg-transparent">
						<table
							class="fx-12 table w-100 table-symbol bg-transparent no-border"
						>
							<thead>
								<th class="no-border">Currency</th>
								<th class="no-border">Amount</th>
								<th class="no-border">Win/Lose</th>
							</thead>
							<tbody>
								<tr
									class="no-border"
									v-for="users in userOwner"
									:key="users.time + '' + users.owner"
								>
									<td
										class="text-center no-border"
										:class="
											users.betType == 'GREEN' ? 'owner-buy' : 'owner-sell'
										"
									>
										{{ users.symbolId }}
									</td>
									<td
										class="text-center no-border"
										:class="
											users.betType == 'GREEN' ? 'owner-buy' : 'owner-sell'
										"
									>
										{{ users.amount }}
									</td>
									<td class="text-center no-border text-draw">
										<i class="fad fa-arrow-up owner-buy"></i
										>{{ ((users.amount * 95) / 100 + users.amount).toFixed(2) }}
										<br />
										<i class="fad fa-arrow-down owner-sell"></i
										>{{ users.amount }}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<!-- MAIN PANEL -->
			</div>
		</div>
	</keep-alive>
</template>
<script>
import { mapMutations, mapState, mapGetters } from 'vuex';

import TradingVue from 'trading-vue-js';
import EMA from '@/components/overlay/EMA.vue';
import RateChart from '@/components/RateChart.vue';
import CurrencyFormat from '@/components/CurrencyFormat.vue';
import RatioBar from '@/components/RatioBar.vue';
import moment from 'moment';
import ten from '@/assets/sound/10.mp3';
import lose from '@/assets/sound/lose.mp3';
import win from '@/assets/sound/win.mp3';
import ting from '@/assets/sound/ting.mp3';
import _ from 'lodash';
export default {
	components: {
		RateChart,
		TradingVue,
		CurrencyFormat,
		RatioBar,
	},
	data() {
		return {
			ohlcv: [],
			history: [],
			localhistory: [],
			tradingChart: {
				width: 100,
				height: 100,
			},
			overlays: [EMA],
			config: {
				DEFAULT_LEN: window.innerWidth > 992 ? 60 : 15,
				TB_BORDER: 1,
				CANDLEW: 0.8,
				GRIDX: window.innerWidth > 992 ? 40 : 100,
				tf: '3M',
				VOLSCALE: 'auto',
			},
			onchart: [
				{
					name: 'EMA, 5',
					type: 'EMA',
					data: [],
					settings: {
						legend: false,
					},
				},
				{
					name: 'EMA, 20',
					type: 'EMA',
					data: [],
					settings: {
						legend: false,
					},
				},
				{
					name: 'Data sections',
					type: 'Splitters',
					data: [[], []],
					settings: {
						legend: false,
						'z-index': 10,
					},
				},
			],
			offchart: [
				{
					name: 'Vol',
					type: 'Volume',
					data: [],
					settings: {
						upper: 100,
						lower: 0,
						legend: false,
						volscale: 0.1,
						colorVolUp: '#dc3545',
						colorVolDw: '#28a745',
					},
				},
			],
			windowEvent: null,
			connection: null,
			win_amount: 0,
			symbolId: 'BTCUSDT',
			time: 0,
			updatedData: 0,
			classInfo: '',
			updataC: 0,
			newKey: 0,
			intervalTime: null,
			firstTimeConnected: true,
			disabledBTN: false,
			show_popup_profit: false,
			connected: false,
			amountBet: {
				sell: 0,
				buy: 0,
			},
			transaction: {
				value: 0,
				currentRatio: 0.95,
				timeRemain: 0,
				round: '',
			},
			bet_data: {
				action: 'onbet',
				data: {
					amount: 0,
					exchangeType: 'binance.com',
					symbolId: 'BTCUSDT',
					userId: '',
					betType: '',
				},
			},
			coinList: [
				{
					id: 1,
					name: 'BTC/USDT',
					image: 'bitcoin.png',
					betSymbol: 'BTCUSDT',
				},
				{
					id: 2,
					name: 'ETH/USDT',
					image: 'ETH.png',
					betSymbol: 'ETHUSDT',
				},
			],
			currentCurrencyID: 1,
			interValReconect: null,
			reConnect: true,
			timerId: null,
			showtrade: true,
			currenyW: 'BTCUSDT',
			toggleSound: true,
			timeOutSlide: null,
			randomEnd: true,
			BuySell: false,
			TimeBS: null,
			userOwner: [],
		};
	},
	computed: {
		...mapGetters({
			user: 'userinfo/User',
		}),
		...mapState(['isLoad', 'userBalance', 'soundsE']),
		predictValue() {
			return this.transaction.value * (this.transaction.currentRatio + 1);
		},
		historyNumberIncrease() {
			return this.history.filter((i) => i == 0).length;
		},
		historyNumberDecrease() {
			return this.history.filter((i) => i == 1).length;
		},
		currentCoin() {
			for (let coin of this.coinList)
				if (coin.id === this.currentCurrencyID) return coin;
			return null;
		},
		coinListAv() {
			let self = this;
			return _.pickBy(this.coinList, function (display) {
				return display.id !== self.currentCurrencyID;
			});
		},
	},
	created() {
		this.createjs.Sound.registerSound(ten, 'ten', 4);
		this.createjs.Sound.registerSound(lose, 'lose', 5);
		this.createjs.Sound.registerSound(win, 'win', 6);
		this.createjs.Sound.registerSound(ting, 'ting', 7);

		let defaultCurrencies = this.$cookies.get('wx_currencyChange');
		if (defaultCurrencies && defaultCurrencies.symbolId !== 'BTCUSDT') {
			this.currentCurrencyID = defaultCurrencies.id;
			this.bet_data.data.symbolId = defaultCurrencies.symbolId;
			this.connnectWSS(defaultCurrencies.symbolId);
		} else {
			this.connnectWSS('BTCUSDT');
		}
	},
	methods: {
		...mapMutations(['onload', 'outload']),
		async changeCurrency(id) {
			this.currentCurrencyID = id;
			this.bet_data.data.symbolId = this.currentCoin.betSymbol;
			let changeCurrency = {
				action: 'onsubscribe',
				symbol: {
					exchangeType: 'binance.com',
					symbolName: this.currentCoin.betSymbol,
					symbolId: this.currentCoin.betSymbol,
				},
			};
			// this.$socket.sendObj(changeCurrency);
			this.onload();
			window.location.reload();
			let dataWithID = {
				currency: changeCurrency,
				id: id,
				symbolId: this.currentCoin.betSymbol,
			};
			this.$cookies.set('wx_currencyChange', JSON.stringify(dataWithID));
		},
		randomUserId: function () {
			return Math.floor(Math.random() * (999999 - 100000)) + 100000;
		},
		checkResizeWindow() {
			this.tradingChart.width = this.$refs.tradingChartWrapper.offsetWidth;
			if (window.innerWidth <= 768) {
				this.tradingChart.height = window.innerHeight - 390;
			} else if (window.innerWidth <= 992 && window.innerWidth >= 768) {
				this.tradingChart.height = window.innerHeight - 380;
			} else {
				this.tradingChart.height = window.innerHeight - 340;
			}
		},
		connnectWSS: async function (symbol) {
			let self = this;
			await this.$connect(
				'wss://autoking.chidetest.com/demo/' +
					this.user.token +
					'/' +
					symbol,
				{ format: 'json' }
			);

			this.$options.sockets.onopen = (data) => {
				clearInterval(this.timerId);
				this.connected = true;
				this.reConnect = false;
				this.ohlcv = [];
				clearTimeout(self.timeOutSlide);
				this.$nextTick(() => {
					setTimeout(
						function () {
							this.showtrade = true;
							this.outload();
						}.bind(this),
						1000
					);
				});
				this.getRoundBet();
				this.switchData();
			};
			this.$options.sockets.onclose = (data) => {};
			this.$socket.onerror = function (event) {
				if (event.type == 'error') {
					self.logout();
				}
			};
			this.$options.sockets.onerror = (data) => {
				this.$store.commit('auth/LOGOUT_SUCCESS');
				this.$router.push('/');
			};
		},
		disconnectWSS: async function () {
			this.$disconnect();
			this.$options.sockets.onclose = (data) => {};
			this.connected = false;
		},
		logout() {
			this.$toastr.w('Please Login Again', 'Something Wrong & Session Timeout');
			this.$store.commit('auth/LOGOUT_SUCCESS');
			this.$router.push('/');
		},
		switchData: async function () {
			let self = this;
			var ohlcv = [];
			this.$options.sockets.onmessage = (event) => {
				let defaultdata = JSON.parse(event.data);
				let actionSocket = defaultdata.action;
				let dataSocket = defaultdata.data;
				let timeIndexSec = 0;
				let timeIndexMin = 0;

				switch (actionSocket) {
					case 'UserInfo':
						self.convertBalance(dataSocket);
						break;
					case 'ListCandle':
					case 'CandleEvery30s':
						if (dataSocket != undefined && dataSocket.length != undefined) {
							ohlcv = [];
							clearTimeout(self.timeOutSlide);
							for (let i = 0; i < dataSocket.length; i++) {
								let each_ohlcv = [];
								each_ohlcv[0] = dataSocket[i].openTime;
								each_ohlcv[1] = dataSocket[i].open;
								each_ohlcv[2] = dataSocket[i].high;
								each_ohlcv[3] = dataSocket[i].low;
								each_ohlcv[4] = dataSocket[i].close;
								each_ohlcv[5] = dataSocket[i].volume;
								this.symbolId = dataSocket[i].symbolId;
								ohlcv.push(each_ohlcv);
							}
							this.ohlcv = ohlcv;
							this.offchart[0].data = ohlcv;
						}
						break;
					case 'PriceEverySec':
						timeIndexSec = moment(dataSocket.closeTime).second();
						timeIndexMin = moment(dataSocket.closeTime).minutes();
						if (self.firstTimeConnected && this.ohlcv.length >= 10) {
							this.$nextTick(() => {
								setTimeout(
									function () {
										this.showtrade = true;
										this.outload();
									}.bind(this),
									100
								);
							});
							self.converWater(
								timeIndexMin,
								timeIndexSec < 30 ? 'order' : 'waiting'
							);
							self.MA(5, this.ohlcv, 0);
							self.MA(20, this.ohlcv, 1);
							// self.caculatorTime(timeIndexSec);
							self.firstTimeConnected = false;
						} else {
							if (self.transaction.timeRemain != timeIndexSec && this.soundsE) {
								self.createjs.Sound.play('ting');
							}

							self.transaction.timeRemain = timeIndexSec;

							if (timeIndexSec < 30) {
								this.transaction.round = 'order';
								self.disabledBTN = false;
							} else {
								this.transaction.round = 'waiting';
								self.disabledBTN = true;
								if (59 - timeIndexSec == 50) {
									if (this.soundsE) {
										self.createjs.Sound.play('ten');
									}
								}
							}
						}
						if (self.BuySell) {
							let timex = dataSocket.closeTime + (0 - timeIndexSec) * 1000;
							self.BuySell = false;
							if (this.amountBet.sell > 0) {
								this.onchart[2].data[0] = [
									timex > 0 ? timex : 30,
									'↓' + this.amountBet.sell,
									0,
									'#dc3545',
									0.87,
								];
							}
							if (this.amountBet.buy > 0) {
								this.onchart[2].data[1] = [
									timex > 0 ? timex : 30,
									'↑' + this.amountBet.buy,
									1,
									'#28a745',
									0.87,
								];
							}
						}
						if (
							this.ohlcv != [] &&
							this.ohlcv != undefined &&
							this.ohlcv[this.ohlcv.length - 1] &&
							this.ohlcv[this.ohlcv.length - 1][4]
						) {
							let data = dataSocket; //close data every second

							var num = this.ohlcv.length - 1;
							var temp = this.ohlcv[num][4];

							this.ohlcv[num][1] = data.open;
							this.ohlcv[num][2] = data.high;
							this.ohlcv[num][3] = data.low;
							this.ohlcv[num][5] = data.volume;

							this.offchart[0].data[num] = this.ohlcv[num];
							var interval = 40;
							var time = 900;

							var difference = (temp - data.close) / interval;

							for (let i = 0; i < interval; i++) {
								if (i % 5 == 0) {
									clearTimeout(self.timeOutSlide);
								}
								self.timeOutSlide = setTimeout(() => {
									let diff = temp - i * difference;
									this.ohlcv[num][4] = diff;
									if (this.ohlcv[num][4] < this.ohlcv[num][1]) {
										self.classInfo = 'info-red';
									}
									if (this.ohlcv[num][4] > this.ohlcv[num][1]) {
										self.classInfo = 'info-green';
									}
									if (self.widthL >= 768) {
										this.updatedData++;
									}
								}, time / interval);
							}

							//back to index charts
							if (self.transaction.timeRemain == 0) {
								this.newKey += 1;
								if (this.newKey == 2) {
									this.updataC += 1;
									this.newKey = 0;
								}
							}
						}
						// this.ohlcv = ohlcv;
						if (timeIndexSec == 0) {
							self.userOwner = [];
							if (this.amountBet.buy > 0 || this.amountBet.sell > 0) {
								this.$store.commit('trade/HISTORY_TRADE', {
									clearOpen: true,
								});
							}
							self.MA(5, ohlcv, 0);
							self.MA(20, ohlcv, 1);
							sessionStorage.removeItem('wx_bet');
							sessionStorage.removeItem('wx_bet_chart');
							this.amountBet.buy = 0;
							this.amountBet.sell = 0;
							this.onchart[2].data[0] = [];
							this.onchart[2].data[1] = [];
						}

						if (timeIndexSec == 4) {
							self.converHistoryByChart();
						}
						break;
					case 'EndGame':
						self.profit(dataSocket);
						break;
					case 'NewBetFromUser':
						if (dataSocket.symbolId == this.currentCoin.betSymbol) {
							self.userOwner.unshift({
								amount: dataSocket.amount,
								betType: dataSocket.betType,
								exchangeType: dataSocket.exchangeType,
								message: dataSocket.message,
								owner: self.randomUserId(),
								symbolId: dataSocket.symbolId,
								time: dataSocket.time,
							});
						}

						break;
					case 'BalanceChange':
						this.$store.commit('userinfo/setBalace', {
							demo: true,
							balance: dataSocket.balance,
						});
						break;
					case 'error':
						if (!dataSocket === 'skip this bet!') {
							this.$toastr.e(dataSocket);
						}

						break;
					case 'BetResult':
						self.convertBetResult(dataSocket);
						break;
					case 'Disconnect':
						self.logout();
						break;
				}
			};
		},
		convertBalance(userinfo) {
			this.$store.commit('userinfo/setBalace', {
				demo: true,
				balance: userinfo.tempBalance,
			});
		},
		profit(result) {
			this.win_amount = result.payMoney;
			if (this.soundsE) {
				this.createjs.Sound.play(result.payMoney > 0 ? 'win' : 'lose');
			}
			this.$store.commit('userinfo/setBalace', {
				demo: true,
				balance: result.balance,
			});
			this.$nextTick(() => {
				this.show_popup_profit = true;
			});
			setTimeout(
				function () {
					this.show_popup_profit = false;
				}.bind(this),
				5000
			);
		},
		convertBetResult(result) {
			let self = this;
			let newmessage = '';
			if (result.tempBalance !== undefined && result.tempBalance !== null) {
				this.$store.commit('userinfo/setBalace', {
					demo: true,
					balance: result.tempBalance,
				});
			}
			this.userOwner.unshift({
				amount: result.amount,
				betType: result.betType,
				exchangeType: 'binance.com',
				message: 'new bet from user',
				owner: this.user.user,
				symbolId: result.symbol,
				time: new Date().getTime(),
			});
			switch (result.betType) {
				case 'GREEN':
					self.amountBet.buy += result.amount;
					newmessage = 'You Are Buy $' + result.amount + '';
					break;
				case 'RED':
					self.amountBet.sell += result.amount;
					newmessage = 'You Are Sell $' + result.amount + '';
					break;
			}
			this.transaction.value = result.amount;
			if (result.tempBalance < 1) {
				this.transaction.value = 0;
			}
			this.BuySell = true;
			this.$toastr.s(newmessage, 'You Are Betting Successfully');
			this.$nextTick(() => {
				setTimeout(
					function () {
						this.setLocalBet();
						this.getRoundBet();
					}.bind(this),
					200
				);
			});
		},
		betBuy: async function () {
			if (parseInt(this.transaction.value) <= 0) {
				this.$toastr.e(
					'Please Enter An Integer Value That Is Greater Than Or Equal To 1',
					'Betting Failed!'
				);
				return;
			}

			if (parseInt(this.transaction.value) > this.user.accounts[0].balance) {
				this.$toastr.e('Your Balance Is Not Enough', 'Betting Failed!');
				return;
			}
			let self = this;
			this.bet_data.data.userId = this.user.user;
			this.bet_data.data.betType = 'GREEN';
			this.bet_data.data.amount = parseInt(this.transaction.value);
			this.$socket.sendObj(this.bet_data);
			self.disabledBTN = true;
		},
		betSell: async function () {
			if (parseInt(this.transaction.value) <= 0) {
				this.$toastr.e(
					'Please Enter An Integer Value That Is Greater Than Or Equal To 1',
					'Betting Failed!'
				);
				return;
			}
			if (parseInt(this.transaction.value) > this.user.accounts[0].balance) {
				this.$toastr.e('Your Balance Is Not Enough', 'Betting Failed!');
				return;
			}
			let self = this;
			this.bet_data.data.userId = this.user.user;
			this.bet_data.data.betType = 'RED';
			this.bet_data.data.amount = parseInt(this.transaction.value);
			this.$socket.sendObj(this.bet_data);
			self.disabledBTN = true;
		},
		getRoundBet() {
			const detail = this.$cookies.get('wx_bet');
			let time = new Date().getMinutes();
			if (detail && detail.time == time) {
				this.amountBet = detail.value;
			} else {
				this.amountBet.buy = 0;
				this.amountBet.sell = 0;
				this.$cookies.remove('wx_bet');
			}
			let onChartBet = this.$cookies.get('wx_bet_chart');
			if (onChartBet && onChartBet.time == time) {
				this.onchart[2].data = onChartBet.value;
			} else {
				this.amountBet.buy = 0;
				this.amountBet.sell = 0;
				this.$cookies.remove('wx_bet');
			}
		},
		setLocalBet() {
			var bet = { value: this.amountBet, time: new Date().getMinutes() };
			var betchart = {
				value: this.onchart[2].data,
				time: new Date().getMinutes(),
			};
			this.$cookies.set('wx_bet', JSON.stringify(bet), '2MIN');
			this.$cookies.set('wx_bet_chart', JSON.stringify(betchart), '2MIN');
		},
		async converWater(min, type) {
			var count = type == 'order' ? 0 : 1;
			var positionLast = ((min % 10) - 1) * 2 + count;
			if (min % 10 == 0) {
				positionLast = 0;
			}
			if (min < 10) {
				positionLast = (min - 1) * 2 + count;
			}
			var StopFor = this.ohlcv.length - 2 - positionLast - 80;
			if (this.ohlcv && this.ohlcv[this.ohlcv.length - 2][1]) {
				for (let i = this.ohlcv.length - 2; i > StopFor; i--) {
					if (this.ohlcv[i][1] > this.ohlcv[i][4]) {
						this.history.push(1);
					} else if (this.ohlcv[i][1] < this.ohlcv[i][4]) {
						this.history.push(0);
					} else if (this.ohlcv[i][1] == this.ohlcv[i][4]) {
						this.history.push(2);
					}
				}
				this.history = this.history.reverse();
			}
		},
		converHistoryByChart() {
			if (this.history.length >= 100) {
				this.history = this.history.slice(20, 100);
			}
			if (
				this.ohlcv[this.ohlcv.length - 2][1] >
				this.ohlcv[this.ohlcv.length - 2][4]
			) {
				this.history.push(1);
			} else if (
				this.ohlcv[this.ohlcv.length - 2][1] <
				this.ohlcv[this.ohlcv.length - 2][4]
			) {
				this.history.push(0);
			} else if (
				this.ohlcv[this.ohlcv.length - 2][1] ==
				this.ohlcv[this.ohlcv.length - 2][4]
			) {
				this.history.push(2);
			} else {
				this.history.push(3);
			}
		},
		checkUndefined(x) {
			if (x === undefined) {
				return false;
			}
			return true;
		},
		caculatorTime(time) {
			let self = this;
			var caser = '';
			//set current time
			this.transaction.timeRemain = time;

			//set case
			time >= 30
				? ((caser = 'waiting'),
				  (this.disabledBTN = true),
				  (this.transaction.round = 'waiting'))
				: ((caser = 'order'),
				  (this.disabledBTN = false),
				  (this.transaction.round = 'order'));
			//case switch
			switch (caser) {
				case 'order':
					clearInterval(self.intervalTime);
					self.intervalTime = setInterval(
						function () {
							if (29 - self.transaction.timeRemain == 0) {
								clearInterval(self.intervalTime);
							}
							self.transaction.timeRemain++;
							if (this.soundsE) {
								self.createjs.Sound.play('ting');
							}
						}.bind(this),
						1000
					);
					break;
				case 'waiting':
					clearInterval(self.intervalTime);
					self.intervalTime = setInterval(
						function () {
							if (59 - self.transaction.timeRemain <= 0) {
								self.transaction.timeRemain = 60;
								clearInterval(self.intervalTime);
							}
							if (!self.disabledBTN) {
								self.disabledBTN = true;
							}
							self.transaction.timeRemain++;
							if (this.soundsE) {
								self.createjs.Sound.play('ting');
							}
							if (self.transaction.timeRemain == 50) {
								if (this.soundsE) {
									self.createjs.Sound.play('ten');
								}
							}
						}.bind(this),
						1000
					);
					break;
			}
		},
		MA(date, data, pos) {
			var i,
				value = 0,
				multiplier = 0;
			var ema = [];
			var daten = date + 1;
			multiplier = 2 / daten;
			for (i = 0; i < data.length; i++) {
				if (ema && ema.length > 0) {
					value = data[i][4] * multiplier + ema[i - 1][1] * (1 - multiplier);
				} else {
					value = data[i][4] * multiplier;
				}
				ema.push([data[i][0], value]);
			}
			this.onchart[pos].data = ema;
		},
	},
	async mounted() {
		window.onload = function () {
			document.getElementById('app').click();
		};

		this.checkResizeWindow();
		this.windowEvent = () => {
			this.checkResizeWindow();
		};
		window.addEventListener('resize', this.windowEvent);
		// simulate load data
		this.onload();
		// this.outload();
	},
	beforeDestroy() {
		// this.setLocalBet();
		this.disconnectWSS();
		clearInterval(this.intervalTime);
		clearTimeout(self.timeOutSlide);
	},
	destroyed() {
		window.removeEventListener('resize', this.windowEvent);
	},
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/colors.scss';
@import '~@/assets/scss/mixins.scss';
@import url('https://fonts.googleapis.com/css2?family=Yusei+Magic&display=swap');
/*** SCROLL BAR ***/
/* width */
::-webkit-scrollbar {
	width: 3px;
	height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #000;
}
.bg-transparent {
	background: transparent !important;
}
.fx-12 {
	font-size: 12px !important;
}
.no-border {
	border: none !important;
}
/* Handle */
::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.privateTransaction {
	height: calc(100vh - 50px);
	overflow: hidden;
}
.privateTransaction {
	background-image: url('~@/assets/images/bg.png');
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	background-attachment: fixed;
}
.bg-form {
	background: rgb(0, 0, 0, 0.3);
	padding: 15px 10px 25px;
	margin-bottom: 0 !important;
}
.bg-form2 {
	margin-top: -80px;
	border-radius: 20px;
	background: rgb(0, 0, 0, 0.3);
	padding: 15px 10px 10px;
}
.bg-form2 > label {
	padding-top: 60px;
	text-align: center;
	text-transform: uppercase;
	font-size: 22px;
	display: block;
}
.predictValue {
	color: $colorTemplate;
}
.mainPanel {
	width: 100%;

	// trading chart
	.tradingChartWrapper {
		position: relative;
		height: calc(100vh - 320px);

		.tradingChartLabel {
			height: 50px;
			border: 1px solid $primaryColor;
			display: inline-block;
			background-image: url('~@/assets/images/transaction/bitcoin.png');
			background-size: 36px 36px;
			background-position: 7px 7px;
			background-repeat: no-repeat;
			font-size: 1.2em;
			font-weight: bold;
			line-height: 50px;
			padding-left: 50px;
			padding-right: 10px;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1000;
		}
	}

	// summary
	.summary {
		.rateChartList {
			margin-left: auto;
			margin-right: auto;
			max-width: 320px;
			width: 100%;

			.secondary {
				width: 30%;
			}

			.primary {
				width: 40%;
			}

			.rateChartWrapper {
				padding: 10px;

				.rateChart {
					width: 100%;
				}
			}
		}

		.lastResult {
			position: relative;

			.info {
				position: absolute;
				top: -55px;
				right: 0;

				.value {
					display: block;
					height: 20px;
					font-size: 0.8em;
					text-transform: uppercase;
					padding: 0 10px;
					background-color: #212121;
					margin: 5px;
					font-weight: bold;
				}

				.increase {
					.label {
						color: #28a745;
					}
				}

				.decrease {
					.label {
						color: #dc3545;
					}
				}

				.draw {
					.label {
						color: darkorange;
					}
				}
			}
		}

		.dotGraph {
			display: flex;
			justify-content: space-between;

			.dotGroup {
				width: 95px;
				display: flex;
				flex-wrap: wrap;
				flex-direction: column;
				max-height: 140px;

				.dot {
					width: 15px;
					height: 15px;
					margin: 2px;
					border-radius: 10px;
					background-color: #ccc;
				}

				.dot.increase {
					background-color: #28a745;
				}
				.dot.draw {
					background-color: darkorange;
				}

				.dot.decrease {
					background-color: #dc3545;
				}
			}
		}
	}
}
.btn-outline-primary {
	color: #fff;
	border-color: $colorTemplate;
}
.sidePanel.right {
	width: 240px;
	margin: -10px -10px 0 15px;
	background: #2b2f42;
	border-radius: 0;
}
.sidePanel {
	border-radius: 0.5em;
	padding: 10px;
	margin: -10px;

	.transactionValue:before {
		content: '$';
		position: absolute;
		top: 1px;
		left: 5px;
	}

	.transactionValue {
		border: 1px solid $colorTemplate;
		line-height: 40px;
		padding: 0 10px 0 20px;
		font-size: 1.3em;
		font-weight: bold;
		position: relative;

		input {
			background-color: transparent;
			border: none;
			color: inherit;
			font-size: inherit;
			width: 100%;
			outline: none;
		}
		input::-webkit-outer-spin-button,
		input::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		/* Firefox */
		input[type='number'] {
			-moz-appearance: textfield;
		}
		.clearValue {
			width: 26px;
			height: 26px;
			padding: 8px;
			text-align: center;
			position: absolute;
			top: 7px;
			right: 7px;
			border: none;

			img {
				width: 100%;
				height: 100%;
				display: block;
			}
		}
	}

	.addValue {
		margin-top: 5px;
		.sampleValue {
			width: calc(33.33% - 1.67px);
			margin-top: 5px;
		}
	}

	.predictValue {
		font-weight: bold;

		.currentRatio {
			font-size: 1.2em;
		}

		.sign,
		.predictValue {
			font-size: 1.8em;
		}
	}

	.transactionAction {
		.info {
			border: 1px solid $primaryColor;
			font-size: 1.1em;
			padding: 10px 0;
			line-height: 1em;
			border-radius: 0.3em;
			text-align: center;
			margin: 5px 0;
		}

		.action {
			font-size: 1.2em;
			font-weight: bold;

			img {
				width: 50px;
				margin-left: 10px;
			}
		}
	}
	.info-red {
		background-color: #dc3545;
	}
	.info-green {
		background: #28a745;
	}
	.transactionAction {
		.bet.action:disabled {
			background: #65757d;
			border: 1px solid black;
		}
	}
}

/*** RESPONSIVE ON PC ***/
@media (min-width: 992px) {
	.privateTransaction {
		height: calc(100vh - 100px);
		overflow: hidden;
	}

	.mainPanel {
		width: calc(100% - 460px);

		// summary
		.summary {
			.rateChartList {
				max-width: 640px;
			}

			.lastResult {
				.info {
					top: -40px;

					.value {
						display: inline-block;
						line-height: 30px;
						height: 30px;
						font-size: 1em;
					}
				}
			}

			.dotGraph {
				padding: 30px 0;
				max-width: 1000px;
				margin: auto;

				.dotGroup {
					width: 120px;

					.dot {
						width: 20px;
						height: 20px;
						margin: 2px;
						border-radius: 10px;
					}
				}
			}
		}
	}

	.sidePanel {
		width: 240px;
		margin: 10px 15px 0 10px;
		height: calc(100vh - 100px);
		overflow-y: auto;
	}
}
@media (max-width: 991px) {
	.d-md-grid {
		display: grid;
	}
	.mainPanel {
		grid-row-start: 1;
		padding-top: 10px;
	}
	.privateTransaction {
		height: auto;
		overflow-y: auto;
	}
	.sidePanel.right {
		display: none !important;
	}
}

.notify-win {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	// background: #0000009c;
	z-index: 200;
	display: flex;
	justify-content: center;
	align-items: center;

	.body-noti {
		width: 100%;
		height: 100%;
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		min-width: 0;
		word-wrap: break-word;
		background-clip: border-box;
		border-radius: 4px;
		background: #00000096;

		img {
			max-width: 150px;
			max-width: auto;
		}

		.content {
			text-align: center;
			font-size: 25px;
			font-family: 'Yusei Magic', sans-serif;
			p.buy {
				text-align: center;
				font-size: 70px;
				color: green;
			}

			p.sell {
				text-align: center;
				font-size: 80px;
				color: red;
			}
			p.amount-win {
				text-align: center;
				font-size: 60px;
				color: green;
			}
			p.amount-lose {
				text-align: center;
				font-size: 60px;
				color: red;
			}
		}
	}
}
.customer-drop {
	height: 45px;
	position: absolute;
	z-index: 1090;

	button {
		padding: 4px !important;
	}
}
.customer-items {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	padding: 0;
}
@media (max-width: 991px) {
	.dotGraph {
		max-width: 500px;
		margin: auto;
		.dotGroup {
			max-height: 110px !important;
		}
	}
	.mainPanel {
		.tradingChartWrapper {
			height: calc(100vh - 380px);
		}
	}
	.mainPanel .summary .lastResult .info {
		display: flex;
		top: -30px;
		right: 15px;
	}
	.customer-drop {
		height: 35px;
		top: -7px;

		.coinImage img {
			width: 25px;
		}
	}
}
@media (max-width: 768px) {
	.mainPanel {
		.tradingChartWrapper {
			height: calc(100vh - 390px);
		}
	}
	.notify-win .body-noti .content p.buy {
		font-size: 33px;
	}
	.notify-win .body-noti .content p.sell {
		font-size: 52px;
	}
	.notify-win .body-noti .content p.amount-win {
		font-size: 46px;
	}
	.notify-win .body-noti .content p.amount-lose {
		font-size: 42px;
	}
}
@media (max-width: 480px) {
}
@media (max-width: 380px) {
}
@media (max-width: 340px) {
	.mainPanel {
		.tradingChartWrapper {
			height: calc(100vh - 380px);
		}
	}
}
.text-draw {
	color: darkorange;
}
.owner-buy {
	color: #28a745;
}
.owner-sell {
	color: #dc3545;
}
</style>

<!-- GLOBAL CSS -->

<style lang="scss">
@import '~@/assets/scss/colors.scss';

.privateTransaction {
	.mainPanel {
		.tradingChartWrapper {
			.tradingChart {
				.trading-vue-legend {
					top: 60px !important;
					margin-left: 0;
				}

				canvas {
					background-color: transparent !important;
				}
			}
		}

		.summary {
			.nav-tabs {
				border: none;

				.nav-item {
					.nav-link {
						border: none;
						color: white;
						background-color: transparent;
						position: relative;
					}

					.nav-link:after {
						content: ' ';
						position: absolute;
						width: 100%;
						height: 1px;
						background-color: white;
						bottom: 0;
						left: 0;
					}

					.nav-link.active {
						font-weight: bold;
					}

					.nav-link.active:after {
						background-color: $primaryColor;
						height: 3px;
						bottom: -1px;
					}
				}
			}
		}
	}
}
.flex-40p {
	flex: 0 0 40% !important;
}
.flex-35p {
	flex: 0 0 35% !important;
}
.flex-30p {
	flex: 0 0 30% !important;
}
</style>