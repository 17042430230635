<template>
	<div class="ratioBar">
		<div class="info d-flex justify-content-between mt-4">
			<div class="left d-flex flex-column justify-content-center align-items-center">
				<span class="fx-20">DOWN</span>
				<span> {{ sell }}</span>
			</div>
			<div class="right d-flex flex-column justify-content-center align-items-center">
				<span class="fx-20">UP</span>
				<span> {{ buy }}</span>
			</div>
		</div>
		<div class="bar">
			<div class="currentValue" :style="`width: ${value * 100}%`"></div>
		</div>
	</div>
</template>

<script>
export default {
	props: ["value", "buy", "sell"],
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/colors.scss";
.fx-20{
	font-size: 20px;
}
.ratioBar {
	.bar {
		height: 8px;
		width: 100%;
		border-radius: 4px;
		background-color: $buyColor;
		overflow: hidden;

		.currentValue {
			background-color: $sellColor;
			height: 8px;
		}
	}

	.info {
		font-size: 0.8em;
	}
}
</style>