<template>
	<div class="privateDashboard py-3">
		<b-container fluid>
			<b-row class="mb-4">
				<b-col cols="12" lg="6">
					<!-- SUMMARY -->
					<div class="otherMetric">
						<b-row>
							<b-col cols="12" sm="6">
								<!-- PROFIT -->
								<div class="profit card-dashboard box d-flex">
									<div class="icon">
										<img
											src="@/assets/images/dashboard/icon-1.svg"
											alt="Profit icon"
											width="42"
										/>
									</div>
									<div class="info">
										<div class="name">{{ $t("dashboard.profit") }}</div>
										<CurrencyFormat
											:value="Statistics.net_profix || 0"
											class="value"
										/>
									</div>
								</div>
								<!-- END PROFIT -->
							</b-col>
							<b-col cols="12" sm="6">
								<!-- INCOME -->
								<div class="profit card-dashboard box d-flex">
									<div class="icon">
										<img
											src="@/assets/images/dashboard/icon-2.svg"
											alt="Income icon"
											width="42"
										/>
									</div>
									<div class="info">
										<div class="name">{{ $t("dashboard.income") }}</div>
										<CurrencyFormat
											:value="Statistics.total_profit || 0"
											class="value"
										/>
									</div>
								</div>
								<!-- END INCOME -->
							</b-col>
							<b-col cols="12" sm="6">
								<!-- INCOME -->
								<div class="profit card-dashboard box d-flex">
									<div class="icon">
										<img
											src="@/assets/images/icons/win.svg"
											alt="Income icon"
											width="50"
										/>
									</div>
									<div class="info">
										<div class="name">{{ $t("dashboard.winRate") }}</div>
										<span class="value"
											>{{ Math.floor(Statistics.percent_win || 0) }}%</span
										>
									</div>
								</div>
								<!-- END INCOME -->
							</b-col>
							<b-col cols="12" sm="6">
								<!-- INCOME -->
								<div class="profit card-dashboard box d-flex">
									<div class="icon">
										<img
											src="@/assets/images/icons/total-trade.svg"
											alt="Income icon"
											width="42"
										/>
									</div>
									<div class="info">
										<div class="name">
											{{ $t("dashboard.totalTransaction") }}
										</div>
										<CurrencyFormat
											:value="Statistics.total_bet || 0"
											class="value"
										/>
									</div>
								</div>
								<!-- END INCOME -->
							</b-col>
						</b-row>
					</div>
					<!-- END SUMMARY -->
				</b-col>
				<b-col cols="12" lg="6">
					<!-- TRADE STATS -->
					<div class="tradeStats box">
						<h3 class="mb-3">{{ $t("dashboard.tradeStats") }}</h3>
						<!-- CHART -->
						<b-row class="chartWrapper mb-3">
							<b-col cols="12" md="6">
								<!-- For more information about vc-donut: https://www.npmjs.com/package/vue-css-donut-chart -->
								<vc-donut
									class="chart"
									background="#111315"
									size.Number="150"
									:sections="sections"
								>
									<div class="name">{{ $t("dashboard.numTransaction") }}</div>
									<div class="value">{{ Statistics.total }}</div>
								</vc-donut>
							</b-col>
							<b-col cols="12" md="6">
								<div
									class="description"
									v-for="section in Sections"
									:key="`desc-${section.color}`"
								>
									<div
										class="icon"
										:style="`border-color: ${section.color}`"
									></div>
									<div class="label" :style="`color:${section.color}`">
										{{ section.label }} (
										{{ parseInt((section.value / 100) * Statistics.total) }} )
									</div>
								</div>
								<h4 class="mt-4 text-left fz-16">
									{{ $t("dashboard.summary") }}
								</h4>
								<RatioBar
									:value="
										Statistics.total_buy /
										(Statistics.total_buy + Statistics.total_sell)
									"
									:sell="Statistics.total_sell"
									:buy="Statistics.total_buy"
								/>
							</b-col>
						</b-row>
						<!-- END CHART -->

						<!-- METRIC -->

						<!-- END METRIC -->
					</div>
					<!-- END TRADE STATS -->
				</b-col>
			</b-row>

			<!-- HISTORY -->
			<div class="history d-block d-lg-flex flex-wrap">
				<div class="flex-fill">
					<h3 class="mb-3">{{ $t("dashboard.table.title") }}</h3>
				</div>
				<div>
					<div class="toolBar d-flex mb-2">
						<b-form-datepicker
							variant="primary"
							id="datepicker-sm"
							size="sm"
							locale="en"
							v-model="from"
							today-button
							reset-button
							close-button
							class="mb-0 customer-date-picker text-white"
							placeholder="yyyy/mm/dd"
							:date-format-options="{
								year: 'numeric',
								month: 'numeric',
								day: 'numeric',
							}"
						></b-form-datepicker>
						<div
							class="mx-2 d-none d-lg-block"
							style="font-size: 1.5em; line-height: 0.5em"
						></div>
						<b-form-datepicker
							variant="primary"
							id="datepicker-sm-2"
							size="sm"
							locale="en"
							v-model="to"
							today-button
							reset-button
							close-button
							class="mb-0 customer-date-picker text-white"
							placeholder="yyyy/mm/dd"
							:date-format-options="{
								year: 'numeric',
								month: 'numeric',
								day: 'numeric',
							}"
						></b-form-datepicker>
						<b-button variant="primary" @click.prevent="search()">{{
							$t("dashboard.table.search")
						}}</b-button>
					</div>
				</div>
			</div>
			<div class="tableWrapper table-responsive">
				<table class="table w-100">
					<thead>
						<th>{{ $t("dashboard.table.orderId") }}</th>
						<th>{{ $t("dashboard.table.startTime") }}</th>
						<th>{{ $t("dashboard.table.symbol") }}</th>
						<th>{{ $t("dashboard.table.selected") }}</th>
						<th>{{ $t("dashboard.table.openPrice") }}</th>
						<th>{{ $t("dashboard.table.closePrice") }}</th>
						<th class="text-right">{{ $t("dashboard.table.value") }}</th>
						<th class="text-right">{{ $t("dashboard.table.payment") }}</th>
					</thead>
					<tbody>
						<tr v-if="!History.data && History.data.length > 0">
							<td class="text-center" colspan="9">
								{{ $t("dashboard.table.nodata") }}
							</td>
						</tr>
						<template v-else>
							<tr v-for="history in History.data" :key="history.order_id">
								<td>{{ history.order_id }}</td>
								<td>{{ getDateTime(history.candleOpenTime) }}</td>
								<td>{{ history.symbolId }}</td>
								<td>{{ history.betType == "GREEN" ? "BUY" : "SELL" }}</td>
								<td>
									{{
										history.candleOpen
											.toFixed(2)
											.replace(/\d(?=(\d{3})+\.)/g, "$&,")
									}}
								</td>
								<td>
									{{
										history.candleClose
											.toFixed(2)
											.replace(/\d(?=(\d{3})+\.)/g, "$&,")
									}}
								</td>
								<td class="text-right">
									{{
										history.amount
											.toFixed(2)
											.replace(/\d(?=(\d{3})+\.)/g, "$&,")
									}}
								</td>
								<td class="text-right">
									{{
										history.pay.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")
									}}
									<i
										class="fad"
										:class="
											history.amount == history.pay
												? 'fa-arrow-down text-danger'
												: 'fa-arrow-up text-success'
										"
									></i>
								</td>
							</tr>
						</template>
					</tbody>
				</table>
				<div class="row px-2 py-2">
					<div class="col-sm-6 pt-1">
						{{ currentPage }} Page Of {{ History.total }} Pages
					</div>
					<div class="col-sm-6 button-div text-right">
						<b-button
							variant="primary"
							class="btn-none"
							@click.prevent="paginate(currentPage - 1)"
							>{{ $t("dashboard.table.prev") }}</b-button
						>
						<b-button
							variant="primary"
							class="btn-none"
							@click.prevent="paginate(currentPage + 1)"
							>{{ $t("dashboard.table.next") }}</b-button
						>
					</div>
				</div>
			</div>
			<!-- END HISTORY -->
		</b-container>
	</div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import CurrencyFormat from "@/components/CurrencyFormat.vue";
import RatioBar from "@/components/RatioBar.vue";
import moment from "moment";
export default {
	components: {
		CurrencyFormat,
		RatioBar,
	},
	data() {
		return {
			sections: [
				{ value: 0, color: "#dc3545", label: "Tổng vòng thua" },
				{ value: 0, color: "darkorange", label: "Tổng vòng hòa" },
				{ value: 0, color: "#28a745", label: "Tổng vòng thắng" },
			],
			winRate: 0.95,
			totalTransaction: 12,
			numTransaction: 3,
			profit: 1.23,
			income: 12.3,
			value: "",
			value2: "",
			from: "",
			to: "",
			currentPage: 1,
		};
	},
	computed: {
		...mapGetters({
			Statistics: "dashboard/Statistical",
			History: "dashboard/History",
		}),
	},
	methods: {
		...mapMutations(["onload", "outload"]),
		Sections() {
			var section = [
				{
					value: this.Statistics.percent_lose,
					color: "#dc3545",
					label: "Tổng vòng thua",
				},
				{
					value: this.Statistics.percent_drawn,
					color: "darkorange",
					label: "Tổng vòng hòa",
				},
				{
					value: this.Statistics.percent_win,
					color: "#28a745",
					label: "Tổng vòng thắng",
				},
			];
			this.sections = section;
		},
		search() {
			this.currentPage = 1;
			this.$store.dispatch("dashboard/req_getHistory", {
				from: this.from,
				to: this.to,
			});
		},
		getDateTime(unix) {
			return moment.unix(unix / 1000).format("HH:mm:ss - DD/MM/YYYY");
		},
		paginate(page) {
			if (page > 0 && page <= this.History.total) {
				this.currentPage = page;
				this.$store.dispatch("dashboard/req_getHistory", {
					from: this.from,
					to: this.to,
					page: page,
				});
			}
		},
	},
	created() {
		this.$store.dispatch("dashboard/req_getStatistical");
		this.$store.dispatch("dashboard/req_getHistory", 0);
	},
	watch: {
		Statistics: {
			handler(nVal, oVal) {
				if (nVal) {
					this.Sections();
				}
			},
		},
	},
	async mounted() {
		// simulate load data
		this.onload();
		await new Promise((resolve) => setTimeout(resolve, 300));
		this.outload();
	},
};
</script>
<style>
.card-dashboard {
	min-height: 165px;
	align-items: center;
}
.table-responsive {
	background: rgb(0 0 0 / 30%);
	border-radius: 10px;
}

.customer-date-picker.form-control {
	background: rgb(0 0 0 / 25%) !important;
	width: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 !important;
	line-height: 1 !important;
	border-left: 0;
	border: 0;

	color: fff;
}
.customer-date-picker.form-control label.form-control.form-control-sm {
	color: #fff !important;
	display: flex;
	justify-content: center;
	align-items: center;
}
.customer-date-picker.form-control .dropdown-menu {
	background: #000000;
	left: auto !important;
}
.customer-date-picker.form-control .dropdown-menu .form-control {
	background: #ffffffa8;
}
</style>
<style lang="scss" scoped>
/*** IMPORTS ***/
@import "~@/assets/scss/mixins.scss";
@import "~@/assets/scss/colors.scss";

.red {
	color: red;
}
.green {
	color: green;
}
/*** SCROLL BAR ***/
/* width */
::-webkit-scrollbar {
	width: 3px;
	height: 3px;
}
.fz-16 {
	font-size: 16px;
}
/* Track */
::-webkit-scrollbar-track {
	background: #000;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 3px;
}
.btn-none {
	padding: 3px 10px;
	background: #6abe43;
	border: none;
	color: #fff;
	font-size: 14px;
	margin: 0 5px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.privateDashboard {
	background-image: url("~@/assets/images/bg.png");
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	background-attachment: fixed;
	min-height: 100vh;
}
h3,
.h3 {
	font-size: 1.5rem;
}
.box {
	border: 1px solid $primaryColor;
	padding: 20px;
	border-radius: 10px;
	margin-bottom: 20px;
	background-color: rgba(0, 0, 0, 0.4);
}

/* CHART STATS */
.chartWrapper {
	.chart {
		width: 150px;
		margin: 0 auto;

		.name,
		.value {
			font-weight: bold;
			font-size: 1.2em;
		}

		.value {
			font-size: 2em;
		}
	}
}
/* END CHART STATS */

/* TRADE STATS */
.tradeStats {
	.description {
		margin: 10px 0;
		display: flex;

		.icon {
			width: 24px;
			height: 24px;
			border: 3px solid $primaryColor;
			margin-right: 10px;
			border-radius: 10px;
		}
	}

	.metrics > :first-child {
		border-right: 1px solid white;
	}

	.metrics {
		text-align: center;
		margin-top: 15px;

		.name {
			font-weight: bold;
		}

		.value {
			font-size: 1.3em;
			font-weight: bold;
		}
	}
}
/* END TRADE STATS */

/* OTHER METRICS */
.otherMetric {
	.box {
		padding: 40px;

		.icon {
			width: 42px;
			height: 42px;
			margin-right: 20px;
		}

		.name {
			font-weight: bold;
			font-size: 1em;
			line-height: 1em;
		}

		.value {
			font-weight: bold;
			font-size: 1.5em;
		}
	}
}
/* END OTHER METRICS */

/* HISTORY */
.history {
	.toolBar {
		border-bottom: 2px solid $primaryColor;

		input {
			background-color: transparent;
			border: none;
			color: white;
			min-width: 10px;
			width: calc(50% - 40px);
			font-size: 0.8em;
		}

		button {
			font-size: 0.8em;
			font-weight: bold;
			min-width: 80px;
			border-radius: 5px 5px 0 0;
		}
	}
}

/* END HISTORY */

/* TABLE */
.tableWrapper {
	width: 100%;

	.table {
		font-size: 0.7em;
		background-color: rgba(0, 0, 0, 0.3);
		width: 100%;

		thead {
			background-color: rgba(0, 0, 0, 0);
		}

		th,
		td {
			border: none;
			padding: 10px 5px;
			text-overflow: ellipsis;
			overflow: hidden;
			text-align: center;
			border: 1px solid $primaryColor;
		}

		th {
			font-weight: normal;
			font-size: 0.9em;
		}
	}
}
/* END TABLE */

/*** RESPONSIVE ON PC ***/
@media (min-width: 992px) {
	.privateDashboard {
		.tableWrapper {
			.table {
				font-size: 1em;
			}
		}
	}

	/* HISTORY */
	.history {
		.toolBar {
			input {
				font-size: 1em;
				width: auto;
			}

			button {
				font-size: 1em;
			}
		}
	}
	/* END HISTORY */
}
.paginate {
	display: flex;
	justify-content: space-around;
	height: 50px;
	align-items: center;

	.button-div {
		flex: 0 0 300px;
		display: flex;
		justify-content: space-evenly;
	}
}
</style>