<template>
	<div class="publicLayout layout">
		<!--TOPBAR -->
		<div class="topBar w-100 d-flex justify-content-end">
			<!-- LOGIN-->
			<b-button
				class="topBarButton text-light"
				variant="none"
				@click="isLoginPanelShow = true"
				v-if="!user"
				>{{ $t("common.login") }}</b-button
			>
			<SidePanel v-model="isLoginPanelShow">
				<!-- LOGIN FORM -->
				<div class="sidePanelForm text-light">
					<h3 class="text-center mb-4">{{ $t("common.login") }}</h3>
					<div class="text-center">
						<input
							:style="showAuthentication ? 'opacity: 0.2;' : ''"
							type="email"
							class="auth-input"
							v-model="users"
							:placeholder="$t('auth.user_name')"
							required
						/>
						<input
							:style="showAuthentication ? 'opacity: 0.2;' : ''"
							type="password"
							class="auth-input"
							v-model="password"
							:placeholder="$t('auth.password')"
							required
						/>
						<input
							v-if="showAuthentication"
							type="text"
							class="auth-input g2a"
							v-model="authCode"
							:placeholder="$t('auth.g2a')"
							required
						/>
						<b-button variant="primary" @click="login">{{
							$t("common.loginCall")
						}}</b-button>
					</div>
				</div>
				<!-- END LOGIN FORM -->
			</SidePanel>
			<!-- END LOGIN-->

			<!-- REGISTER -->
			<b-button
				class="topBarButton"
				variant="primary"
				@click="isRegisterPanelShow = true"
				v-if="!user"
				>{{ $t("common.register") }}</b-button
			>
			<SidePanel v-model="isRegisterPanelShow">
				<!-- REGISTER FORM -->
				<div class="sidePanelForm text-light">
					<h3 class="text-center mb-4">{{ $t("common.register") }}</h3>

					<form @submit.prevent="onRegister()">
						<div
							class="text-center d-flex flex-column justify-content-center align-items-center align-self-center"
						>
							<input
								type="text"
								class="auth-input"
								v-model="register.email"
								:placeholder="$t('auth.email')"
								required
							/>
							<input
								type="password"
								class="auth-input"
								v-model="register.password"
								:placeholder="$t('auth.password')"
								required
							/>
							<input
								type="password"
								class="auth-input"
								v-model="register.password_confirm"
								:placeholder="$t('auth.re_password')"
								required
							/>
							<input
								type="text"
								class="auth-input"
								v-model="register.sponsor"
								:placeholder="$t('auth.sponsor')"
							/>
							<b-button variant="primary" type="submit">{{
								$t("common.register")
							}}</b-button>
						</div>
					</form>
				</div>
				<!-- END REGISTER FORM -->
			</SidePanel>
			<!-- END REGISTER -->

			<!-- DASHBOARD -->
			<router-link
				class="btn btn-primary topBarButton"
				to="/private/dashboard"
				v-if="user"
				>{{ $t("common.dashboard") }}</router-link
			>
			<!-- END DASHBOARD -->

			<!-- LOGOUT -->
			<b-button
				class="topBarButton"
				variant="primary"
				@click="logout"
				v-if="user"
				>{{ $t("common.logout") }}</b-button
			>
			<!-- END LOGOUT -->
		</div>
		<!-- ENDTOPBAR -->

		<!-- MAIN -->
		<router-view />
		<!-- END MAIN -->

		<!-- FOOTER SECTION -->
		<section class="footerWrapper text-white homeSection">
			<b-container class="footerContainer my-3 py-3 my-lg-4 py-lg-4">
				<b-row>
					<!-- ABOUT -->
					<b-col sm="6" lg="3" class="mb-3">
						<h4>{{ $t("home.footer.about.title") }}</h4>
						<a href="#">{{ $t("home.footer.about.platform") }}</a>
					</b-col>
					<!-- END ABOUT -->

					<!-- SUPPORT -->
					<b-col sm="6" lg="3" class="mb-3">
						<h4>{{ $t("home.footer.support.title") }}</h4>
						<a href="#">{{ $t("home.footer.support.about") }}</a>
						<a href="#">{{ $t("home.footer.support.question") }}</a>
					</b-col>
					<!-- END SUPPORT -->

					<!-- SECURITY -->
					<b-col sm="6" lg="3" class="mb-3">
						<h4>{{ $t("home.footer.security.title") }}</h4>
						<a href="#">{{ $t("home.footer.security.policy") }}</a>
						<a href="#">{{ $t("home.footer.security.risk") }}</a>
						<a href="#">{{ $t("home.footer.security.responsibility") }}</a>
					</b-col>
					<!-- END SECURITY -->

					<!-- LANGUAGE -->
					<b-col sm="6" lg="3" class="mb-3">
						<b-dropdown
							:text="currentLanguage"
							class="languageSwitch m-md-2"
							variant="outline-primary"
						>
							<template #button-content class="languageButton">
								<img
									:src="getFlag(currentLanguage)"
									:alt="languageName[currentLanguage]"
								/>
								{{ languageName[currentLanguage] }}
							</template>
							<b-dropdown-item-button
								v-for="(lang, code) of languageName"
								:key="lang"
								@click="changeLanguage(code)"
							>
								<img :src="getFlag(code)" :alt="lang" /> {{ lang }}
							</b-dropdown-item-button>
						</b-dropdown>
					</b-col>
					<!-- END LANGUAGE -->
				</b-row>
				<hr />
				<b-row>
					<b-col lg="2"></b-col>
					<b-col lg="10">
						<p>
							Risk Warning: Trading and investing in digital options involves
							significant level of risk and is not suitable and/or appropriate
							for all clients. Please make sure you carefully consider your
							investment objectives, level of experience and risk appetite
							before buying or selling any digital asset. You should be aware of
							and fully understand all the risks associated with trading and
							investing in digital assets, you should not invest funds you
							cannot afford to lose. You are granted limited non-exclusive
							rights to use the IP contained in this site for personal,
							non-commercial, non-transferable use only in relation to the
							services offered on the site.
						</p>
					</b-col>
				</b-row>
			</b-container>
		</section>
		<!-- END FOOTER SECTION -->
	</div>
</template>

<script>
// @ is an alias to /src
import SidePanel from "@/components/SidePanel.vue";
import { mapState } from "vuex";

export default {
	name: "Home",
	data() {
		return {
			isLoginPanelShow: false,
			isRegisterPanelShow: false,
			currentLanguage: "vi",
			languageName: {
				vi: "Tiếng Việt",
				en: "English",
			},
			showAuthentication: false,
			users: "",
			password: "",
			authCode: "",
			register: {
				email: "",
				password: "",
				password_confirm: "",
				sponsor: "",
			},
		};
	},
	components: {
		SidePanel,
	},
	computed: {
		...mapState(["user"]),
	},
	created() {
        const sponsor = this.$route.query.sponsor;
        if(sponsor){
            this.register.sponsor = sponsor;
            this.isRegisterPanelShow =true;
        }
		this.unsubscribe = this.$store.subscribe((mutation, state) => {
			switch (mutation.type) {
				case "auth/LOGIN_SUCCESS":
					this.$toastr.s(this.$t("auth.login_success"), "Sucessfully");
					this.isLoginPanelShow = false;
					this.$router.push({ name: "dashboard" });
					break;

				case "auth/REGISTER_SUCCESS":
					this.isLoginPanelShow = true;
					this.isRegisterPanelShow = false;
					break;
				case "auth/REQUIRE_AUTH":
					this.showAuthentication = true;
					break;
			}
		});
	},
	beforeDestroy() {
		this.unsubscribe();
	},
	methods: {
		login() {
			if (this.users && this.password) {
				this.$store.dispatch("auth/req_PostLogin", {
					email: this.users,
					password: this.password,
					authCode: this.authCode,
				});
			}
		},
		onRegister() {
			this.$store.dispatch("auth/req_PostRegister", this.register);
		},
		logout() {
			this.$store.commit("auth/LOGOUT_SUCCESS");
		},
		changeLanguage(code) {
			this.currentLanguage = code;
			this.$i18n.locale = code;
		},

		getFlag(code) {
			var images = require.context("@/assets/images/home/", false, /\.png$/);
			return images("./" + code + ".png");
		},
	},
};
</script>

<style lang="scss" scoped>
/*** IMPORTS ***/
@import "~@/assets/scss/mixins.scss";
@import "~@/assets/scss/colors.scss";

/*** TOPBAR ***/
.topBar {
	height: 50px;
	padding: 7px 20px;
	line-height: 36px;
	background-image: linear-gradient(0deg, #101416, #42403f);
	position: fixed;
	top: 0;
	left: 0;
	z-index: 900;

	.topBarButton {
		font-weight: bold;
		margin: 0 0.3em;
	}
}

/*** FOOTER SECTION ***/
.footerWrapper.homeSection {
	min-height: auto;
}

.footerWrapper {
	@include overlayBackground("~@/assets/images/home/footerBackground.jpg", 0);
	border-bottom: 30px solid $primaryColor;

	h4 {
		text-transform: uppercase;
		font-family: Roboto;
		letter-spacing: 0;
	}

	a {
		color: white;
		display: block;
		margin: 0.5em 0;
	}

	.languageSwitch {
		img {
			margin: 0.3em 0.5em 0.3em 0;
		}
	}

	hr {
		border-bottom: 1px solid $primaryColor;
		width: 100%;
	}
}

/*** RESPONSIVE ON PC ***/
@media (min-width: 992px) {
	.topBar {
		.topBarButton {
			line-height: 1em;
			font-size: 1.3em;
		}
	}
}
input.auth-input {
	background-color: transparent;
	border: 1px solid white;
	color: inherit;
	font-size: inherit;
	width: 100%;
	max-width: 300px;
	border-radius: 5px;
	margin-bottom: 15px;
	outline: none;
}
</style>