<template>
	<div class="privateAgency">
		<!-- MAIN PART -->
		<div v-if="Agency && Agency.isBuyAgency == 0">
			<div class="main d-flex align-items-center">
				<b-container>
					<b-row>
						<b-col class="left" cols="12" lg="6">
							<div class="title">{{ $t("agency.title") }}</div>
							<b-button variant="primary" class="button" v-b-modal.buyAgency
								>{{ $t("agency.buyNow") }} $100</b-button
							>
							<b-modal id="buyAgency" title="Chấp nhận tham gia" hide-footer>
								<div class="form-group text-center">
									<img
										:src="require('../assets/images/shake.png')"
										style="width: 100%; max-width: 160px"
									/>
								</div>
								<div class="form-group text-center">
									<label for=""
										>Bạn cần thanh toán $100 để mua Quyền Đại lý. Bạn muốn tiếp
										tục?</label
									>
								</div>
								<div
									class="d-flex justify-content-center align-items-center submit"
								>
									<b-button
										variant="primary"
										class="buttonSubmit"
										@click="buyAgency"
										>Xác nhận</b-button
									>
								</div>
							</b-modal>
						</b-col>
						<b-col class="right" cols="12" lg="6">
							<div class="form-group">
								<label for="">{{ $t("agency.registerLink") }}</label>
								<div class="inputGroup" v-if="Agency">
									<input type="text" v-model="Agency.link_ref" readonly />
									<b-button
										variant="primary"
										v-clipboard:copy="Agency.link_ref"
										v-clipboard:success="onCopy"
										>{{ $t("agency.copy") }}</b-button
									>
								</div>
							</div>
							<div class="form-group">
								<label for="">{{ $t("agency.inviteCode") }}</label>
								<div class="inputGroup" v-if="Agency && Agency.user">
									<input
										type="text"
										v-model="Agency.user.userId"
										v-if="Agency.user.userId"
										readonly
									/>
									<b-button
										variant="primary"
										v-clipboard:copy="Agency.user.userId || 0"
										v-clipboard:success="onCopy"
										>{{ $t("agency.copy") }}</b-button
									>
								</div>
							</div>
						</b-col>
					</b-row>
				</b-container>
			</div>
			<div class="footer">
				<b-container>
					<b-row>
						<b-col class="d-flex box" cols="12" lg="4">
							<div class="icon">
								<img src="@/assets/images/agency/icon-1.png" alt="Icon" />
							</div>
							<div class="info">
								<div class="name">{{ $t("agency.box0.name") }}</div>
								<div class="desc">{{ $t("agency.box0.desc") }}</div>
							</div>
						</b-col>

						<b-col class="d-flex box" cols="12" lg="4">
							<div class="icon">
								<img src="@/assets/images/agency/icon-1.png" alt="Icon" />
							</div>
							<div class="info">
								<div class="name">{{ $t("agency.box1.name") }}</div>
								<div class="desc">{{ $t("agency.box1.desc") }}</div>
							</div>
						</b-col>

						<b-col class="d-flex box" cols="12" lg="4">
							<div class="icon">
								<img src="@/assets/images/agency/icon-1.png" alt="Icon" />
							</div>
							<div class="info" cols="12">
								<div class="name">{{ $t("agency.box2.name") }}</div>
								<div class="desc">{{ $t("agency.box2.desc") }}</div>
							</div>
						</b-col>
					</b-row>
				</b-container>
			</div>
		</div>
		<div v-else>
			<div class="agency-u d-flex align-items-center">
				<b-container>
					<b-row class="mb-2">
						<b-col class="mb-5" cols="12"> </b-col>
						<b-col cols="12" lg="6" class="mb-4 mx-auto">
							<div class="card-statis">
								<div class="ib-level">
									<div class="img" v-if="Agency && Agency.Rank">
										<img
											:src="Agency.Rank.Image"
											v-if="Agency.Rank.Image"
											alt=""
										/>
									</div>
								</div>
								<div class="ib-text text-center mt-2">
									<span class="name-ib">Rank 1</span>
								</div>
							</div>
						</b-col>
						<b-col cols="12" lg="6" class="mb-4">
							<div class="card-statis">
								<div class="form-group">
									<label for="">{{ $t("agency.registerLink") }}</label>
									<div class="inputGroup" v-if="Agency">
										<input type="text" v-model="Agency.link_ref" readonly />
										<b-button
											variant="primary"
											v-clipboard:copy="Agency.link_ref"
											v-clipboard:success="onCopy"
											>{{ $t("agency.copy") }}</b-button
										>
									</div>
								</div>
								<div class="form-group">
									<label for="">{{ $t("agency.inviteCode") }}</label>
									<div class="inputGroup" v-if="Agency && Agency.user">
										<input
											type="text"
											v-model="Agency.user.userId"
											v-if="Agency.user.userId"
											readonly
										/>
										<b-button
											variant="primary"
											v-clipboard:copy="Agency.user.userId || 0"
											v-clipboard:success="onCopy"
											>{{ $t("agency.copy") }}</b-button
										>
									</div>
								</div>
							</div>
						</b-col>
						<b-col cols="12" lg="12" class="mb-4">
							<div class="card-statis statis" v-if="Agency">
								<div class="box-statis">
									<div class="left">
										{{ $t("agency.boxstatis.f1_total_trade") }}
									</div>
									<div class="right">{{ Agency.totalF1Trade }} USDT</div>
								</div>
								<div class="box-statis">
									<div class="left">
										{{ $t("agency.boxstatis.f1_total_agency") }}
									</div>
									<div class="right">
										{{ Agency.totalF1Agency }}
										{{ $t("agency.boxstatis.member") }}
									</div>
								</div>
								<div class="box-statis">
									<div class="left">
										{{ $t("agency.boxstatis.total_trade") }}
									</div>
									<div class="right">{{ Agency.totalTrade }} USDT</div>
								</div>
								<div class="box-statis">
									<div class="left">
										{{ $t("agency.boxstatis.total_commission") }}
									</div>
									<div class="right">{{ Agency.totalComission }} USDT</div>
								</div>
								<!-- <div class="box-statis">
									<div class="left">
										{{ $t("agency.boxstatis.bonus_deposit") }} 
                                        <b-button variant="success" class="ml-2">
                                            Withdraw
                                        </b-button>
									</div>
									<div class="right">
										{{ Agency.totalComission }} USDT
									</div>
								</div> -->
								<div class="box-statis">
									<div class="left">
										{{ $t("agency.boxstatis.pending_rank_commission") }}
									</div>
									<div class="right">{{ Agency.pendingRank }} USDT</div>
								</div>
								<div class="box-statis">
									<div class="left">
										{{ $t("agency.boxstatis.backcom_pending") }}
									</div>
									<div class="right">{{ Agency.pendingBackcom }} USDT</div>
								</div>
							</div>
						</b-col>
					</b-row>
					<b-row>
						<b-col cols="12">
							<div class="historyTab">
								<table class="historyTable">
									<tr>
										<th class="text-left">{{ $t("agency.table.user_id") }}</th>
										<th class="text-left">{{ $t("agency.table.email") }}</th>
										<th>
											{{ $t("agency.table.agency_level") }}
										</th>
										<th>F</th>
										<th class="text-right">{{ $t("agency.table.total") }}</th>
										<th class="text-center">{{ $t("agency.table.parent") }}</th>
									</tr>
									<template v-if="Agency && Agency.memberList.length > 0">
										<tr v-for="member in Agency.memberList" :key="member.userId">
											<td class="text-left">{{member.userId}}</td>
											<td class="text-left">{{member.email}}</td>
											<td>{{member.agencyLevel}}</td>
											<td>{{member.f}}</td>
											<td class="text-right"> {{member.totalTrade || 0}} USDT</td>
											<td>{{member.ponser}}</td>
										</tr>
									</template>
									<template v-else>
										<tr>
											<td colspan="6" class="text-center">
												{{ $t("wallet.mainTab.nodata") }}
											</td>
										</tr></template
									>
								</table>
							</div>
						</b-col>
					</b-row>
				</b-container>
			</div>
		</div>
		<!-- END FOOTER PART -->
	</div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";

export default {
	data() {
		return {
			inviteCode: "32A1265",
		};
	},
	computed: {
		registerLink() {
			return `https://wefinex.net/reg?r=${this.inviteCode}`;
		},
		...mapGetters({
			Agency: "agency/Agency",
		}),
	},
	methods: {
		...mapMutations(["onload", "outload"]),
		onCopy: function (e) {
			alert("You just copied: " + e.value);
		},
		async buyAgency() {
			// this.$startLoading();
			// await this.$recaptchaLoaded();
			// const token = await this.$recaptcha("deposit");
			this.$store.dispatch("agency/postBuyAgency", {});
		},
	},
	created() {
		this.$store.dispatch("agency/req_getAgency");
	},
	async mounted() {
		// simulate load data
		this.onload();
		await new Promise((resolve) => setTimeout(resolve, 300));
		this.outload();
	},
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/colors.scss";
@import "~@/assets/scss/mixins.scss";

.privateAgency {
	background-image: url("~@/assets/images/bg.png");
	background-size: cover;
	background-position: center center;
	background-attachment: fixed;
	background-repeat: no-repeat;
}

.main {
	min-height: calc(100vh - 220px);
	padding: 50px 0;

	.left {
		margin-bottom: 30px;

		.title {
			font-size: 2em;
			font-weight: bold;
			margin-bottom: 10px;
		}

		.button {
			font-size: 1.5em;
			font-weight: bold;
		}
	}

	.right {
		background-color: rgba(0, 0, 0, 0.3);
		border: 1px solid $primaryColor;
		border-radius: 20px;
		padding: 30px 20px;

		.inputGroup {
			border: 1px solid $primaryColor;
			border-radius: 5px;
			padding: 5px;
			display: flex;
			overflow: hidden;

			input {
				border: none;
				background-color: transparent;
				color: white;
				width: calc(100% - 80px);
			}

			button {
				width: 80px;
				border-radius: 5px;
			}

			input:active,
			input:focus {
				border: none;
				outline: none;
			}
		}
	}
}

.footer {
	background-color: #2b2f42;
	padding: 20px 0;

	.box {
		margin: 20px 0;

		.icon {
			border: 1px solid $primaryColor;
			width: 60px;
			height: 60px;
			padding: 10px;
			border-radius: 30px;
			overflow: hidden;

			img {
				width: 100%;
				height: 100%;
			}
		}

		.info {
			width: calc(100% - 80px);
			margin-left: 10px;

			.name {
				font-size: 1.1em;
				font-weight: bold;
				text-transform: uppercase;
			}
		}
	}
}

/*** RESPONSIVE ON PC ***/
@media (min-width: 992px) {
	.main {
		.left {
			.title {
				font-size: 2.5em;
			}
		}
	}
}
.agency-u {
	min-height: calc(100vh - 100px);
	padding: 50px 0px;

	.ib-level {
		width: 215px;
		border: 1px solid white;
		padding: 15px;
		height: 215px;
		display: flex;
		justify-content: center;
		align-self: center;
		border-radius: 50%;
		background: #9e9e9e26;
		margin-top: -100px;
		margin-left: auto;
		margin-right: auto;

		.img {
			width: 200px;
			margin: auto;

			img {
				width: 100%;
				height: auto;
			}
		}
	}
	.name-ib {
		font-size: 2em;
		font-weight: bold;
	}
	// .card-statis {
	// 	width: 100%;
	// 	min-height: 250px;
	// 	background: red;
	// }
	.card-statis {
		background-color: rgba(0, 0, 0, 0.3);
		border: 1px solid $primaryColor;
		border-radius: 20px;
		width: 100%;
		min-height: 250px;
		padding: 30px 20px;
		position: relative;

		.inputGroup {
			border: 1px solid $primaryColor;
			border-radius: 5px;
			padding: 5px;
			display: flex;
			overflow: hidden;

			input {
				border: none;
				background-color: transparent;
				color: white;
				width: calc(100% - 80px);
			}

			button {
				width: 80px;
				border-radius: 5px;
			}

			input:active,
			input:focus {
				border: none;
				outline: none;
			}
		}
	}
	.historyTab {
		border: 1px solid $primaryColor;
		font-size: em;
		background-color: rgba(0, 0, 0, 0.3);
		border-radius: 15px;
		overflow: hidden;

		.historyTable {
			width: 100%;

			th,
			td {
				border: none;
				padding: 10px 5px;
				text-overflow: ellipsis;
				overflow: hidden;
				text-align: center;
			}

			th {
				font-weight: normal;
				font-size: 1.2em;
			}

			tr {
				border-top: 1px solid $primaryColor;
			}
		}
	}
}
.card-statis.statis {
	padding: 0px;
	display: flex;
	justify-content: space-around;
	flex-direction: column;

	.box-statis {
		padding: 10px 20px;
		font-size: 1.1em;
		font-weight: 600;
		border-bottom: 1px solid white;
		display: flex;
		justify-content: space-between;
	}
	.box-statis:last-child {
		border-bottom: 0 none;
	}
}
</style>