import Vue from 'vue'
import Vuex from 'vuex'
import Auth from './users/Auth.js'
import Dashboard from './users/Dashboard.js'
import Agency from './agency/Agency.js'
import Core from './setting/Core.js'
import Trade from './wallet/Trade.js'
import UserInfo from './users/UserInfo.js';
import Ticket from './ticket/Ticket.js';
import wallet from './wallet/Index.js';

Vue.use(Vuex)

Vue.config.devtools = true
    // Return vuex store
export default new Vuex.Store({
    state: {
        isLoad: false,
        soundsE: true
    },
    mutations: {
        // loader
        onload(state) {
            state.isLoad = true;
        },

        outload(state) {
            state.isLoad = false;
        },

        //get sound
        getSound(state, sound) {
            state.soundsE = sound;
        }
    },
    actions: {},
    modules: {
        auth: Auth,
        core: Core,
        trade: Trade,
        dashboard: Dashboard,
        userinfo: UserInfo,
        agency: Agency,
        ticket: Ticket,
        wallet: wallet
    }
})