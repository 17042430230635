<template>
	<div class="PrivateProfile my-4 p-2">
		<!-- UPDATE ACCOUNT -->
		<b-container class="mb-4 box rounded p-3">
			<h5>{{ $t("profile.personalInfo") }}</h5>
			<div class="d-flex avatarWrapper my-4">
				<div class="avatar mr-4">
					<img
						v-b-modal.uploadImage
						src="@/assets/images/icons/profile-user.svg"
						alt="Profile"
						width="50"
					/>
				</div>
				<b-col cols="12">
					<b-row>
						<b-col cols="12" md="10">
							<div class="form-group" v-if="UserInfo">
								<p class="mb-1">ID: {{ UserInfo.userId }}</p>
								<p>Email: {{ UserInfo.email }}</p>
							</div>
						</b-col>
					</b-row>
				</b-col>
			</div>
		</b-container>
		<!-- END UPDATE ACCOUNT -->

		<!-- VERIFY ACCOUNT -->
		<b-container class="mb-4 box rounded p-3">
			<h5 class="mb-4">Xác minh tài khoản</h5>
			<b-row>
				<b-col cols="12" md="3" class="mb-2">
					<strong>{{ $t("profile.verifyIdentify") }}</strong>
				</b-col>
				<b-col cols="12" md="6" class="mb-2">
					<i> {{ $t("profile.verifyIdentifyDesc") }}</i>
				</b-col>
				<b-col cols="12" md="3" class="mb-2 text-md-center">
					<b-button
						v-b-modal.uploadImage
						variant="outline-primary"
						class="avatarChangeButton force"
					>
						<img
							src="@/assets/images/icons/camera.svg"
							alt=""
							width="18"
							height="18"
							class="mr-2"
							style="vertical-align: middle"
						/>

						{{
							UserKYC.status == -1
								? " Xác thực"
								: UserKYC.status == 1
								? "Đã Xác thực"
								: " Đợi Xác thực"
						}}
					</b-button>

					<b-modal id="uploadImage" ref="MKYC" title="Xác minh" hide-footer>
						<div class="form-group">
							<label for="">D/Passport Number</label>
							<input type="text" v-model="passport" class="form-control" />
						</div>
						<div class="form-group">
							<div class="imgInfo">
								<p class="text-left">
									<span class="fileSize">ID/Passport Image</span><br />
									<span class="fileSize text-warning"
										>* Make sure the image is full and clear and the format is
										jpg, jpeg.</span
									><br />
									<span class="fileSize text-warning"
										>* Please use image up to maximum 2MB size</span
									>
								</p>
							</div>
							<div
								class="avatarEmpty text-center"
								v-if="!passport_image || passport_image == null"
							>
								<input
									@change="onFileChange($event, 0)"
									type="file"
									accept=".jpg,.jpeg,.png"
									class="fileUpload"
								/>
								<svg
									data-v-de2bfc3e=""
									xmlns="http://www.w3.org/2000/svg"
									width="40"
									height="40"
									viewBox="0 0 89.156 95.1"
								>
									<g
										data-v-de2bfc3e=""
										id="upload-and-send"
										transform="translate(0 0)"
										opacity="0.3"
									>
										<path
											data-v-de2bfc3e=""
											id="Path_34910"
											data-name="Path 34910"
											d="M25.038,1.016a2.972,2.972,0,0,1,4.476,0l20.8,23.775a2.972,2.972,0,0,1-2.241,4.927H36.188V68.353a2.972,2.972,0,0,1-2.972,2.972H21.329a2.972,2.972,0,0,1-2.972-2.972V29.719H6.469a2.972,2.972,0,0,1-2.235-4.927Z"
											transform="translate(17.306 0)"
											fill="#fefefe"
										></path>
										<path
											data-v-de2bfc3e=""
											id="Path_34911"
											data-name="Path 34911"
											d="M77.269,11V28.831H11.888V11H0V34.775a5.944,5.944,0,0,0,5.944,5.944H83.213a5.944,5.944,0,0,0,5.944-5.944V11Z"
											transform="translate(0 54.381)"
											fill="#fefefe"
										></path>
									</g>
								</svg>
								<p data-v-de2bfc3e="">{{ $t("profile.clickToUpload") }}</p>
							</div>
							<div v-else class="avatarEmpty show-img">
								<img :src="passport_image" width="225" />
								<button
									class="button removi-img block bg-theme-1 text-white"
									@click="removeImage(0)"
								>
									Remove image
								</button>
							</div>
						</div>

						<div class="form-group">
							<div class="imgInfo">
								<p class="text-left">
									<span class="fileSize">ID/Passport Image With Selfie</span
									><br />
									<span class="fileSize text-warning">* Your face.</span><br />
									<span class="fileSize text-warning">* Your ID/Passport.</span
									><br />
									<span class="fileSize text-warning"
										>* Please use image up to maximum 2MB size</span
									>
								</p>
							</div>
							<div
								class="avatarEmpty text-center"
								v-if="!passport_image_selfie || passport_image_selfie == null"
							>
								<input
									@change="onFileChange($event, 1)"
									type="file"
									accept=".jpg,.jpeg,.png"
									class="fileUpload"
								/>
								<svg
									data-v-de2bfc3e=""
									xmlns="http://www.w3.org/2000/svg"
									width="40"
									height="40"
									viewBox="0 0 89.156 95.1"
								>
									<g
										data-v-de2bfc3e=""
										id="upload-and-send"
										transform="translate(0 0)"
										opacity="0.3"
									>
										<path
											data-v-de2bfc3e=""
											id="Path_34910"
											data-name="Path 34910"
											d="M25.038,1.016a2.972,2.972,0,0,1,4.476,0l20.8,23.775a2.972,2.972,0,0,1-2.241,4.927H36.188V68.353a2.972,2.972,0,0,1-2.972,2.972H21.329a2.972,2.972,0,0,1-2.972-2.972V29.719H6.469a2.972,2.972,0,0,1-2.235-4.927Z"
											transform="translate(17.306 0)"
											fill="#fefefe"
										></path>
										<path
											data-v-de2bfc3e=""
											id="Path_34911"
											data-name="Path 34911"
											d="M77.269,11V28.831H11.888V11H0V34.775a5.944,5.944,0,0,0,5.944,5.944H83.213a5.944,5.944,0,0,0,5.944-5.944V11Z"
											transform="translate(0 54.381)"
											fill="#fefefe"
										></path>
									</g>
								</svg>
								<p data-v-de2bfc3e="">{{ $t("profile.clickToUpload") }}</p>
							</div>
							<div v-else class="avatarEmpty show-img">
								<img :src="passport_image_selfie" width="225" />
								<button
									class="button removi-img block bg-theme-1 text-white"
									@click="removeImage(1)"
								>
									Remove image
								</button>
							</div>
						</div>
						<div
							class="d-flex justify-content-center align-items-center submit"
						>
							<b-button
								variant="primary"
								v-if="UserKYC.status == -1"
								:disabled="
									!passport_image || !passport_image_selfie || !passport
								"
								class="buttonSubmit btn-disable"
								@click.prevent="uploadImage()"
								>{{ $t("profile.update") }}</b-button
							>
						</div>
					</b-modal>
				</b-col>
			</b-row>
		</b-container>
		<!-- END VERIFY ACCOUNT -->

		<!-- SECURITY -->
		<b-container class="mb-4 box rounded p-3">
			<h5 class="mb-4">{{ $t("profile.security") }}</h5>
			<b-row class="mb-3">
				<b-col cols="12" md="3" class="mb-2">
					<strong>{{ $t("profile.password") }}: </strong>
				</b-col>
				<b-col cols="12" md="6" class="mb-2">
					<i>{{ $t("profile.passwordDesc") }}</i>
				</b-col>
				<b-col cols="12" md="3" class="mb-2 text-md-center">
					<b-button variant="outline-primary" v-b-modal.changePass
						>Đổi</b-button
					>
					<b-modal
						ref="changePass"
						id="changePass"
						:title="$t('profile.change')"
						hide-footer
					>
						<form @submit.prevent="onChangePass()">
							<div class="form-group">
								<label for="">{{ $t("profile.currentPass") }}</label>
								<div class="d-flex">
									<input
										:type="showPass[1] ? 'text' : 'password'"
										v-model="changePass.User_Password"
										class="form-control"
										required
									/>
									<span
										class="btm-s-h"
										v-show="showPass[1]"
										@click="showPass[1] = !showPass[1]"
									>
										<i class="fas fa-eye"></i>
									</span>
									<span
										class="btm-s-h"
										v-show="!showPass[1]"
										@click="showPass[1] = !showPass[1]"
									>
										<i class="fas fa-eye-slash"></i>
									</span>
								</div>
							</div>
							<div class="form-group">
								<label for="">{{ $t("profile.newPass") }}</label>
								<div class="d-flex">
									<input
										:type="showPass[2] ? 'text' : 'password'"
										v-model="changePass.User_New_Password"
										class="form-control"
										required
									/>
									<span
										class="btm-s-h"
										v-show="showPass[2]"
										@click="showPass[2] = !showPass[2]"
									>
										<i class="fas fa-eye"></i>
									</span>
									<span
										class="btm-s-h"
										v-show="!showPass[2]"
										@click="showPass[2] = !showPass[2]"
									>
										<i class="fas fa-eye-slash"></i>
									</span>
								</div>
								<div
									class="d-flex flex-column justify-content-center text-center"
								>
									<small
										v-if="score < 1 && changePass.User_New_Password"
										class="mt-1 text-warning"
										>Please Change Stong Password</small
									>
									<small
										v-if="
											score > 1 && score < 2 && changePass.User_New_Password
										"
										class="mt-1 text-warning"
										>I Think You Needed Stong Password</small
									>
									<Password
										v-model="changePass.User_New_Password"
										:strength-meter-only="true"
										:toggle="true"
										@score="showScore"
										class="w-100"
									/>
								</div>
							</div>
							<div class="form-group">
								<label for="">{{ $t("profile.reNewPass") }}</label>

								<div class="d-flex">
									<input
										:type="showPass[3] ? 'text' : 'password'"
										v-model="changePass.User_Re_New_Password"
										class="form-control"
										required
									/>
									<span
										class="btm-s-h"
										v-show="showPass[3]"
										@click="showPass[3] = !showPass[3]"
									>
										<i class="fas fa-eye"></i>
									</span>
									<span
										class="btm-s-h"
										v-show="!showPass[3]"
										@click="showPass[3] = !showPass[3]"
									>
										<i class="fas fa-eye-slash"></i>
									</span>
								</div>
							</div>
							<div
								class="d-flex justify-content-center align-items-center submit"
							>
								<b-button
									:disabled="score < 1"
									variant="primary"
									type="submit"
									class="buttonSubmit"
									>{{ $t("profile.update") }}</b-button
								>
							</div>
						</form>
					</b-modal>
				</b-col>
			</b-row>
			<b-row class="mb-3">
				<b-col cols="12" md="3" class="mb-2">
					<strong>{{ $t("profile.2faCode") }}: </strong>
				</b-col>
				<b-col cols="12" md="6" class="mb-2">
					<i>{{ $t("profile.2faCodeDesc") }}</i>
				</b-col>
				<b-col cols="12" md="3" class="mb-2 text-md-center">
					<b-button variant="outline-primary" v-b-modal.authi>{{
						UserAuth.enable ? "Tắt Auth" : "Bật Auth"
					}}</b-button>
					<b-modal
						id="authi"
						ref="changeAuth"
						:title="UserAuth.enable ? 'Tắt Authencation' : 'Bật Authencation'"
						hide-footer
					>
						<form @submit.prevent="changAuth()">
							<div class="form-group text-center" v-if="!UserAuth.enable">
								<label for=""
									>Authenticator Secret Code:
									<span>{{ UserAuth.secret }}</span></label
								>
							</div>
							<div class="form-group text-center" v-if="!UserAuth.enable">
								<img v-lazy="UserAuth.qr" width="300" class="mx-auto" />
							</div>
							<div class="form-group">
								<label for=""
									>Enter the verification code provided by your authentication
									app</label
								>
								<input type="text" v-model="authCode" class="form-control" />
							</div>
							<div
								class="d-flex justify-content-center align-items-center submit"
							>
								<b-button
									variant="primary"
									:disabled="!authCode"
									type="submit"
									class="buttonSubmit btn-disable"
									>{{ $t("profile.update") }}</b-button
								>
							</div>
						</form>
					</b-modal>
				</b-col>
			</b-row>
		</b-container>
		<!-- END SECURITY -->
	</div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
export default {
	components: { Password: () => import("vue-password-strength-meter") },
	data: () => ({
		reader: new FileReader(),
		passport_image: "",
		passport_image_selfie: "",
		passport: "",
		kyc: {
			passport_image: "",
			passport_image_selfie: "",
			passport: "",
		},
		authCode: "",
		score: null,
		changePass: {
			User_Password: "",
			User_New_Password: "",
			User_Re_New_Password: "",
		},
		showPass: {
			1: true,
			2: true,
			3: true,
		},
	}),
	computed: {
		...mapGetters({
			UserInfo: "userinfo/UserInfo",
			UserAuth: "userinfo/UserAuth",
			UserKYC: "userinfo/UserKYC",
		}),
	},
	created() {
		this.$store.dispatch("userinfo/req_getAuth");
		this.$store.dispatch("userinfo/req_getKYC");
		this.unsubscribe = this.$store.subscribe((mutation) => {
			switch (mutation.type) {
				case "userinfo/CHANGE_PASSWORD_SUCCESS":
					this.$refs["changePass"].hide();
					break;
				case "userinfo/CHANGE_AUTH_SUCCESS":
					this.$refs["changeAuth"].hide();
					break;
				case "userinfo/UPLOAD_KYC_SUCCESS":
					this.$refs["MKYC"].hide();
					break;
			}
		});
	},
	beforeDestroy() {
		this.unsubscribe();
	},
	methods: {
		...mapMutations(["onload", "outload"]),

		onFileChange(e, type) {
			var files = e.target.files || e.dataTransfer.files;
			if (!files.length) return;

			this.reader.onload = (e) => {
				if (type == 0) {
					this.passport_image = e.target.result;
					this.kyc.passport_image = files[0];
				} else {
					this.passport_image_selfie = e.target.result;
					this.kyc.passport_image_selfie = files[0];
				}
			};
			this.reader.readAsDataURL(files[0]);
		},
		uploadImage() {
			this.kyc.passport = this.passport;
			this.$store.dispatch("userinfo/req_postKYC", this.kyc);
		},
		removeImage(type) {
			if (type == 0) {
				this.passport_image = "";
			} else {
				this.passport_image_selfie = "";
			}
		},
		showScore(score) {
			this.score = score;
		},
		onChangePass() {
			if (this.changePass.User_Password == this.changePass.User_New_Password) {
				this.$toastr.e(
					"Please Don't Use New Password Like Old Password",
					"Change Password Failed"
				);
			} else {
				this.$store.dispatch(
					"userinfo/req_postChangePassword",
					this.changePass
				);
			}
		},
		changAuth() {
			this.$store.dispatch("userinfo/req_postAuth", {
				authCode: this.authCode,
				secret: this.UserAuth.secret,
			});
		},
	},
	async mounted() {
		// simulate load data
		this.onload();
		await new Promise((resolve) => setTimeout(resolve, 300));
		this.outload();
	},
};
</script>

<style lang="scss" scoped>
/*** IMPORTS ***/
@import "~@/assets/scss/mixins.scss";
@import "~@/assets/scss/colors.scss";
.avatarEmpty {
	width: 100%;
}
.avatarEmpty img {
	width: 100%;
}
.box {
	// border: 1px solid $primaryColor;
	background-color: rgb(0, 0, 0, 0.3);
}
.close {
	color: #fff;
}
.removi-img {
	position: absolute;
	top: calc(50% - 15px);
	left: 0;
	right: 0;
	margin: auto;
	background: #f3d91f;
	border: 1px #f3d91f solid;
	border-radius: 5px;
	padding: 7px 20px;
	opacity: 0;
	z-index: 4;
	transition: 0.3s;
	transform: translateY(100%);
	color: #000 !important;
}
.show-img:hover .removi-img {
	transform: translateY(0);
	opacity: 1;
}
.show-img {
	padding: 10px !important;
	height: auto !important;
	position: relative !important;
}
.show-img img {
	position: relative;
	z-index: 1;
	max-width: 225px;
}
.show-img::after {
	background: rgb(0, 0, 0, 0.5);
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	border-radius: 10px;
	height: 100%;
	z-index: 2;
	content: "";
}
svg {
	font-size: 30px;
}
#uploadImage {
	.avatarEmpty {
		background: #111111;
		padding: 45px 0;
		color: #424242;
		border-radius: 10px;
		height: 180px;
		margin-bottom: 23px;
		width: 100%;
		position: relative;
		cursor: pointer;
		text-align: center;

		.fileUpload {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			z-index: 22;
			outline: none;
			opacity: 0;
		}

		p {
			font-size: 20px;
			font-weight: bold;
			margin-top: 20px;
			margin-bottom: 0;
		}
	}

	.imgInfo {
		text-align: center;
		margin-bottom: 20px;

		.colorGreen {
			color: #25e69c;
			margin: 0 10px;
		}
	}
}
.PrivateProfile {
	background-image: url("~@/assets/images/bg.png");
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	background-attachment: fixed;
	min-height: 100vh;
	margin-top: 0 !important;
	padding-top: 1.5rem !important;
}
.btm-s-h {
	display: flex;
	justify-content: center;
	align-content: center;
	align-self: center;
	align-items: center;
	border: 1px solid #f3d91f;
	height: calc(1.5em + 0.75rem + 2px);
	border-radius: 5px;
	padding: 2px;
	cursor: pointer;
}
</style>