<style>
@import "~@/assets/scss/chatBox.css";
</style>
<template>
  <div class="chat-box">
    <div class="fabs list-room" @click="ToggleChatBox()">
      <a id="prime" class="fab"
        ><i class="prime zmdi zmdi-comment-outline"></i
      ></a>
    </div>

    <div class="fabs create-new-room" @click="OpenCreateNewRoom()">
      <a id="prime" class="fab"
        ><i class="prime zmdi zmdi-collection-plus"></i
      ></a>
    </div>

    <div class="fabs" v-show="IsChatBoxVisible()">
      <div class="chat is-visible">
        <div class="chat_header" v-bind:class="{ inChatRoom: IsChatRoomVisible() }">
          <span v-show="IsChatRoomVisible()" class="icon"  @click="LeaveRoom()">
            <i class="material-icons">chevron_left</i>
          </span>
          <p class="agent">{{HeaderContent}}</p>
          <span v-show="IsChatRoomVisible() && IsChatRoomOwner()" class="icon" @click="ToggleRoomManagement()">
            <i class="material-icons">more_vert</i>
          </span>
        </div>
        <div v-if="HasMessage()" class="bg-white text-danger p-2 text-center">
          {{ GetMessage() }}
        </div>

        <div class="chat_body chat_login" style="display: block">
          <!-- <ManageMenu /> -->
          <NewChatRoom
            v-show="IsCreateNewRoomVisible()"
            :onClose="OpenListRoom"
            :onSubmit="CreateNewRoom"
          />

          <ChatRoom
            v-show="IsChatRoomVisible()"
            ref="chatRoom"
            :chatServer="chatServer"
            :onSendMessage="SendMessage"
            :onDeleteRoom="DeleteRoom"
            :loggedInUserId="user"
          />

          <ListRoom
            v-show="IsListRoomVisible()"
            ref="listRoom"
            :onSelectRoom="SelectRoom"
          />

          <UnlockRoom
            v-show="IsUnlockRoomVisible()"
            ref="unlockRoom"
            :onUnlock="UnlockRoom"
            :onClose="OpenListRoom"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NewChatRoom from "@/components/chatBox/NewChatRoom.vue";
import ChatRoom from "@/components/chatBox/ChatRoom.vue";
import ListRoom from "@/components/chatBox/ListRoom.vue";
import UnlockRoom from "@/components/chatBox/UnlockRoom.vue";

var States = {
  ListRoom: "ListRoom",
  CreateNewRoom: "CreateNewRoom",
  ChatRoom: "ChatRoom",
  UnlockRoom: "UnlockRoom",
};

var ChatBox = {
  props: ["user", "token"],
  components: {
    NewChatRoom,
    ChatRoom,
    ListRoom,
    UnlockRoom,
  },
  data() {
    var chatServer;
    chatServer = {
      connection: null,
      connected: false,
      sendRoomCommand:function(actionName, eventName, room, metaData){
        let payload = {action: actionName || "onchat", data: { event: eventName, room : room}};

        payload.data = Object.assign({}, payload.data, metaData);
        console.log("sendRoomCommand",payload);
        this.connection.send(JSON.stringify(payload));
      },
      //<params name="actionName">action name</params>
      //<params name="eventName">event name</params>
      //<params name="roomPayload">room object in payload. Let it null if not pass any room parameter</params>
      //<params name="messagePayload">message object in payload. Let it null if not pass any message parameter</params>
      //<params name="userPayload">user object in payload. Let it null if not pass any message parameter</params>
      sendChatCommand(
        actionName,
        eventName,
        roomPayload,
        messagePayload,
        userPayload,
        messagesPayload
      ) {
        let payload = {
          action: actionName || "onchat",
          data: {
            event: eventName,
          },
        };

        if (roomPayload) {
          payload.data.room = roomPayload;
        }

        if (messagePayload) {
          payload.data.message = messagePayload;
        }

        if (userPayload) {
          payload.data.user = userPayload;
        }

        if (messagesPayload) {
          payload.data.messages = messagesPayload;
        }

        console.log("Send command");
        console.log(payload);

        this.connection.send(JSON.stringify(payload));
      },

      joinDefaultRoom() {
        this.sendChatCommand("onchat", "JOIN_DEFAULT_ROOM");
      },

      onConversChat(data) {
        console.log(data);
      },

      createRoom(newRoom) {
        console.log("Call createRoom");
        this.sendChatCommand("onchat", "CREATE_ROOM", newRoom);
      },

      joinRoom(room) {
        this.sendChatCommand("onchat", "JOIN_ROOM", room);
      },

      listRoomChat() {
        this.sendChatCommand("onchat", "AVAILABLE_ROOM");
      },
      listMemChat(room){
        console.log("Call list member");
        this.sendChatCommand("onchat","USER_IN_ROOM",room);
      },

      inviteChat(room, userIdList) {
        console.log(userIdList, room);
        if (room == null || !Array.isArray(userIdList)) {
          return;
        }

        this.sendChatCommand("onchat", "INVITE_USERS", room, null, userIdList);
      },
      kickUser(room, userIdList) {
        console.log("Call KickUser");  
        this.sendRoomCommand("onchat", "KICK", room , {users : userIdList});
      },

      destroyRoom(room) {
        console.log("Call destroyRoom", room);
        this.sendChatCommand("onchat", "DELETE_ROOM", room, null, null);
      },

      deleteMessage() {
        this.sendChatCommand( 
          "onchat",
          "DELETE_MESSAGE",
          this.roomIndex,
          null,
          null,
          [{ from: "long", time: 123123123123 }]
        );
      },

      checkBadWord() {
        // let t = this;
        // t.chatConnection.send(
        //   JSON.stringify({
        //     action: "onchat",
        //     data: {
        //       event: "BAD_WORDS",
        //       badWords: ["dcm", "baba", "die"],
        //     },
        //   })
        // );
      },

      blockChat(userIdList) {
        console.log("Call Block");
        this.sendChatCommand("onchat","BLOCK_CHAT",{users : userIdList})},
      unblockChat(userIdList) {
        console.log("Call Un Block Chat");
        this.sendChatCommand("onchat","UNBLOCK_CHAT",{users : userIdList})},
      leaveRoom(room) {
        console.log("Call leaveRoom");
        this.sendChatCommand("onchat", "LEAVE_ROOM", room);
      },
      sendMessage(room, message) {
        this.sendChatCommand("onchat", "SEND_CHAT", room, message, null, null);
      },
      getUsersInRoom(room){
        this.sendChatCommand("onchat","USER_IN_ROOM",room,null,null);
      }
    };

    return {
      Visible: false,
      Message: "",
      HeaderContent : "Rooms",
      State: States.ListRoom,
      chatServer: chatServer,
    };
  },
  computed: {},
  methods: {    
    HasMessage: function () {
      return this.Message != null && this.Message.trim() != "";
    },
    SetMessage:function(Message){
      this.Message = Message;
    },
    GetMessage: function () {
      return this.Message;
    },
    ClearMessage:function(){
     return this.Message = "";
    },
    IsUnlockRoomVisible: function () {
      return this.State == States.UnlockRoom;
    },
    IsChatBoxVisible: function () {
      return this.Visible;
    },
    IsListRoomVisible: function () {
      return this.State == States.ListRoom;
    },
    IsCreateNewRoomVisible: function () {
      return this.State == States.CreateNewRoom;
    },
    IsChatRoomVisible: function () {
      return this.State == States.ChatRoom;
    },
    IsChatRoomOwner:function(){
      console.log(this.$refs);
      return this.$refs.chatRoom.IsChatRoomOwner();
    },

    ToggleChatBox: function () {
      this.ClearMessage();
      if (this.State == States.CreateNewRoom) {
        this.State = States.ListRoom;
        return;
      }
      this.Visible = !this.Visible;
    },

    ToggleRoomManagement:function(){
      if(!this.IsChatRoomVisible){
        return;
      }
      this.$refs.chatRoom.ToggleManagement();
    },

    LeaveRoom:function(){
      if(!this.IsChatRoomVisible){
        return;
      }

      this.chatServer.leaveRoom(this.$refs.chatRoom.GetRoom());
      this.OpenListRoom();
    },

    DeleteRoom:function(room){
      this.chatServer.destroyRoom(room);
    },
    

    OpenCreateNewRoom: function () {
      this.ClearMessage();
      this.Visible = true;
      this.State = States.CreateNewRoom;
    },
    OpenListRoom: function () {
      this.ClearMessage();
      this.State = States.ListRoom;
      this.SetHeaderContent("Rooms");
    },
    SetHeaderContent : function(headerText){
      console.log(headerText);
      this.HeaderContent = headerText;
    },

    OpenChatRoom: function () {
      this.ClearMessage();
      this.State = States.ChatRoom;
    },
    CreateNewRoom: function (room) {
      this.chatServer.createRoom(room);
    },

    SelectRoom: function (room) {
      // console.log(room);
      // console.log(this.user);
      this.ClearMessage();
      if (room.password && this.user != room.owner) {
        
        this.$refs.unlockRoom.Open(room);
        this.State = States.UnlockRoom;
        return;
      }     
      this.chatServer.joinRoom(room);
    },

    UnlockRoom: function (room) {
      this.chatServer.joinRoom(room);
    },

    SendMessage: function (room, message) {
      this.chatServer.sendMessage(room, message);
    },
    ConnectChatServer: async function (user, token) {
      console.log(user, token);
      var _ = this;
      console.log("Starting connection to WebSocket Server");
      _.chatServer.connection = await new WebSocket(
        `wss://autoking.chidetest.com/chatsk/${token}/${user}`
      );

      _.chatServer.connection.onopen = function (event) {
        console.log("Successfully connected to the echo websocket server...");
        _.connected = true;
        _.switchData();
        _.chatServer.listRoomChat();
        _.OpenListRoom();
      };
    },
    switchData() {
      const _ = this;
      _.chatServer.connection.onmessage = function (event) {
        let message = JSON.parse(event.data);
        console.log(message);
        let eventData = message.data.event;
        let action = message.action;
        let data = message.data;

        //handle error from chatserver
        if (action === "error") {
          _.SetMessage(data);
          return;
        }
        _.ClearMessage();
        //handle chat events from server
        switch (eventData) {
          case "JOIN_ROOM":
            _.chatServer.getUsersInRoom(data.room);
            if(!_.IsChatRoomVisible()){
              _.$refs.chatRoom.Open(data.room, data.messages);
              _.OpenChatRoom();
            }
            break;
          case "JOIN_DEFAULT_ROOM":
            // chatBox.ChatRoomForm.LoadRoom(data.room, data.messages);
            // chatBox.HideAll();
            // chatBox.ChatRoomForm.Show();
            break;
          case "AVAILABLE_ROOM":
            _.$refs.listRoom.LoadRooms(data.rooms);
            break;
          case "INVITE_USERS":
            console.log(data);

            break;
          case "DELETE_ROOM":
            _.chatServer.listRoomChat();
            if(_.IsChatRoomVisible() // We are in a chat room
            && _.$refs.chatRoom.IsSameRoom(data.room)) // And the room same with deleted room
            {
              _.OpenListRoom();
              _.$refs.chatRoom.Close();
            }
            break;
          case "DELETE_MESSAGE":
            break;
          case "KICK":
            break;
          case "LEAVE_ROOM":
            console.log("LEAVE_ROOM",data);
            if(_.IsChatRoomVisible() // We are in a chat room
            && _.$refs.chatRoom.IsSameRoom(data.room)) // And the room same with deleted room
            {
              _.chatServer.getUsersInRoom(data.room);
            }
            break;
          case "SEND_CHAT":
            _.$refs.chatRoom.AddNewMessages(data.messages);
            break;
          case "CREATE_ROOM":
            _.chatServer.listRoomChat();
            _.$refs.chatRoom.Open(data.room, []);
            _.OpenChatRoom();
            break;
          case "USER_IN_ROOM":
            console.log("USER_IN_ROOM",data);
            _.$refs.chatRoom.UsersInRoomsWereChanged(data.users);
            break;
          case "BAD_WORDS":
            break;
        }
      };
    },
  },
  mounted() {
    this.ConnectChatServer(this.user, this.token);
  },
  watch: {
    $route() {},
    "chatBox.ChatRoomForm.Messages": {
      handler(newval) {
        if (newval) {
          this.$nextTick(() => {
            console.log(
              "Mess ChatBox",
              this.$refs.messageWrapperItem,
              this.$refs.messageWrapperItem.scrollHeight
            );
            let el = this.$refs.messageWrapperItem;
            if (el) {
              el.scrollTop = el.scrollHeight + 120;
            }
          });
        }
      },
    },
  },
};

export default ChatBox;
</script>
