<style>
.room-manager-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1000;
  padding: 8px;
  box-sizing: border-box;
}

.room-manager-wrapper * {
  box-sizing: border-box;
}
</style>

<template>
  <div id="chat_converse" class="chat_conversion chat_converse">
    <div class="message-wrapper" ref="messageWrapperItem">
      <template v-for="message in Messages">
        <span
          class="chat_msg_item chat_msg_item_admin"
          v-if="message.from !== loggedInUserId"
          :key="`${message.time}_${message.from}_1`"
        >
          <div class="chat_avatar">
            <img
              class="profile-image"
              src="~@/assets/images/user.png"
              alt=""
            />
            
          </div>
          <div class="id-chat">ID-{{ message.from }}</div>
          <div class="content-chat-admin">{{ message.content }}</div>
          <span class="status-admin">
            {{ moment(date).format("DD-MM-YYYY [at] HH:mm") }}
          </span>
        </span>

        <span
          class="chat_msg_item chat_msg_item_user"
          v-else
          :key="`${message.time}_${message.from}_2`"
        >
          <div class="id-chat">ID-{{ message.from }}</div>
          <div class="content-chat-user">{{ message.content }}</div>
          <span class="status-user">
            {{ moment(date).format("DD-MM-YYYY [at] HH:mm") }}
          </span>
        </span>
      </template>
    </div>

    <div class="fab_field">
      <a id="fab_send" @click="SendMessage(Message)" class="fab is-visible">
        <i class="zmdi zmdi-mail-send"></i>
      </a>
      <textarea
        id="chatSend"
        name="chat_message"
        placeholder="Send a message"
        v-model.trim="Message"
        class="chat_field chat_message"
        @keyup.enter="SendMessage(Message)"
      ></textarea>
    </div>

    <div class="room-manager-wrapper" v-show="IsManagementVisible()">
      <ChatRoomManager
        :onInvitation="SendInvitation"
        :onDeleteRoom="DeleteRoom"
        :users="UsersInRoom"
        :onKickUser="KickUser"
        :onBlockUser="BlockUser"
        :isChatRoomOwner="CheckChatRoomOwner"
      />
    </div>
  </div>
</template>

<script>
import ChatRoomManager from "@/components/chatBox/ChatRoomManager.vue";
export default {
  components: { ChatRoomManager },
  props: [
    "loggedInUserId",
    "onSendMessage",
    "onDeleteRoom",
    "chatServer",
    "onInvitation",
    "onBlockUser",
  ],
  data() {
    return {
      ManagementVisible: false,
      Message: "",
      Room: null,
      Messages: [],
      UsersInRoom: [],
    };
  },
  computed: {},
  methods: {
    SetMessage:function(){
      return this.Message;
    },
    IsChatRoomOwner: function () {
      return this.loggedInUserId === this.Room.owner;
    },
    CheckChatRoomOwner: function (userId) {
      return userId === this.Room.owner;
    },
    Open: function (room, messages) {
      messages.sort(function (messA, messB) {
        return messA.time - messB.time;
      });

      this.Room = room;
      this.Messages = messages;
      this.ManagementVisible = false;
      this.$parent.SetHeaderContent(this.Room.name || "Unknown");
    },
    Close: function () {
      this.Room = null;
      this.Messages = [];
      this.ManagementVisible = false;
    },
    UsersInRoomsWereChanged: function (usersInRoom) {
      console.log("UsersInRoomsWereChanged", usersInRoom);
      this.UsersInRoom = usersInRoom || [];
    },
    SendMessage: function () {
      if (this.Message.trim() == "") {
        return;
      }
      this.onSendMessage(this.Room, this.Message);
      this.Message = "";
    },
    DeleteRoom: function () {
      console.log("----------------------", this.Room);
      this.onDeleteRoom(this.Room);
    },
    AddNewMessages: function (newMessages) {
      for (let i = 0; i < newMessages.length; i++) {
        this.Messages.push(newMessages[i]);
      }
    },
    IsSameRoom: function (room) {
      return this.Room.name === room.name && this.Room.owner === room.owner;
    },
    IsManagementVisible: function () {
      return this.ManagementVisible;
    },
    ToggleManagement: function () {
      this.ManagementVisible = !this.ManagementVisible;
    },
    GetRoom: function () {
      return this.Room;
    },
    KickUser: function (userId) {
      this.chatServer.kickUser(this.Room, [userId]);
      //console.log("KickUser",this.Room, );
    },
    BlockUser: function (userId) {
      console.log("BlockUser", this.Room, userId);
      this.chatServer.blockChat(this.Room, [userId]);
    },
    SendInvitation: function (invitedUserId) {
      console.log("Invite", this.Room, invitedUserId);
      this.chatServer.inviteChat(this.Room, [invitedUserId]);
      this.invitedUserId = "";
    },
  },
  mounted() {},
  watch: {
    Messages: {
      handler(newval) {
        if (newval) {
          this.$nextTick(() => {
            let el = this.$refs.messageWrapperItem;
            if (el) {
              el.scrollTop = el.scrollHeight + 120;
            }
          });
        }
      },
    },
  },
};
</script>