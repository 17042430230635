<style scoped>
.chat-room-manager {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #fff;
  display: grid;
  grid-template-rows: 40px 10px 40px auto 10px 40px;
  color: black;
  padding: 4px;
  border-radius: 6px;
}

.chat-room-manager .vertical-line {
  height: 1px;
  width: 60%;
  background-color: #999;
}

.chat-room-manager .vertical-line {
  align-self: center;
  justify-self: center;
}

.chat-room-manager .new-member-container {
  display: grid;
  grid-template-columns: 60px auto 40px;
  align-items: center;
}

.chat-room-manager .new-member-container > input {
  width: 100%;
}

.chat-room-manager .chat-room-manager .send-invitation {
  padding: 4px;
  cursor: pointer;
}

.chat-room-manager .manage-action-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat-room-manager .manage-action-container > * {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  text-align: center;
}

.chat-room-manager .list-member-container {
  text-align: left;
  padding: 6px 10px;
  overflow: auto;
}

.chat-room-manager .user-list-container {
  padding: 0;
  margin: 0;
}

.chat-room-manager .user-list-container > .user-item {
  padding: 10px 4px;
  display: grid;
  grid-template-columns: auto 80px;
  cursor: pointer;
}

.user-list-container > .user-item .item-action {
  display: none;
}

.chat-room-manager .user-list-container > .user-item:hover {
  background-color: rgb(243, 242, 242);
}
.chat-room-manager .user-list-container > .user-item:hover .item-action {
  display: block;
}

.user-list-container > .user-item .item-action * {
  cursor: pointer;
  font-size: 12px;
  padding: 4px;
}

.chat-room-manager .txt-user {
  margin: 0;
  padding: 0 8px;
  line-height: 40px;
  text-align: left;
}
</style>


<template>
  <div class="chat-room-manager">
    <!-- Invite part -->
    <div class="new-member-container">
      <span>Invited ID</span>
      <input type="text" v-model="invitedUserId" />
      <i
        class="material-icons invite_icon send-invitation"
        type="icon"
        style="cursor: pointer"
        @click="SendInvitation(invitedUserId)"
        >add_circle_outline</i
      >
    </div>

    <div class="vertical-line"></div>
    <h6 class="txt-user">Users in room</h6>
    <!-- List members part -->
    <div class="list-member-container">
      <ul class="user-list-container">
        <li class="user-item" v-for="user in users" v-bind:key="user">
          <span>ID: {{ user }}</span>
          <div class="item-action" v-if="!isChatRoomOwner(user)">
            <span @click="KickUser(user)"
              ><i class="fas fa-user-slash" style="font-size: 23px"></i></span
            >
            <span @click="BlockUser(user)"
              ><i class="fas fa-comment-slash" style="font-size: 23px"></i
            ></span>
          </div>
          <!-- <span class="edit-icon action-icon" v-show="showActionBtn">
            <div>Kick</div>
          </span>
          <span class="delete-icon action-icon" v-show="showActionBtn">
            <div
              style="color: red"
              class="badge badge-danger mr-2"             
            >
              Block
            </div>
          </span> -->
        </li>
      </ul>
    </div>

    <div class="vertical-line"></div>

    <!-- Actions -->
    <div class="manage-action-container">
      <button @click="DeleteRoom()">Delete Room</button>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "users",
    "onDeleteRoom",
    "onKickUser",
    "onBlockUser",
    "isChatRoomOwner",
    "onInvitation",
  ],
  data() {
    return {
      showActionBtn: false,
      invitedUserId: "",
    };
  },
  computed: {},
  methods: {
    DeleteRoom: function () {
      this.onDeleteRoom();
    },
    SetUsers: function (users) {
      this.users = users;
    },
    KickUser: function (userId) {
      this.onKickUser(userId);
    },
    BlockUser: function (userId) {
      console.log("Call block chat");
      this.onBlockUser(userId);
    },
    SendInvitation: function (invitedUserId) {
      console.log("Call invite");
      // console.log(invitedUserId);
      this.onInvitation(invitedUserId);
    },
  },
  mounted() {},
  watch: {},
};
</script>