<template>
  <div style="min-height: 300px;padding: 10px;">
    <template v-for="(room, index) in Rooms">
      <p class="m-0" :key="index" @click="SelectRoom(room)">
        <span> {{ room.name }}</span>
        <i v-if="room.password" class="fas fa-lock"></i>
      </p>
    </template>
    <!-- <ul>
      <li
        class="m-0"
        v-for="(room, index) in Rooms"
        :key="index"
        @click="SelectRoom(room)"
      >
        <span style="color:black">{{ room.name }}</span>
        <i v-if="room.password" class="fas fa-lock"></i>
      </li>
    </ul> -->
  </div>
</template>

<script>
export default {
  props: ["onSelectRoom"],
  data() {
    return {
      Rooms: [],
    };
  },
  computed: {},
  methods: {
    LoadRooms: function (rooms) {
      this.Rooms = rooms;
    },
    SelectRoom: function (room) {
      this.onSelectRoom(room);
    },
  },
  mounted() {},
  watch: {},
};
</script>