<template>
  <div class="loaderWrapper">
    <div class="loader"></div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/colors.scss';

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loaderWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: rgba(0, 0, 0, 0.5);

  .loader {
    border: 6px solid #212121;
    border-radius: 50%;
    border-top: 6px solid $primaryColor;
    width: 60px;
    height: 60px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;

    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -30px;
    margin-left: -30px;
  }
}
</style>