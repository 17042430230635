const axios = require("../config/axios.config.js").default;
const param = "dashboard/";
export default {
    namespaced: true,
    state: () => ({
        statistical: {},
        history:{
            data:[],
            total:0
        }
    }),

    getters: {
        Statistical: (state) => {return state.statistical},
        History: (state) => {return state.history}
    },

    actions: {
        req_getStatistical: function ({ commit }){
            axios.get(param+"statistical").then(function(response){
                if(response){
                    commit("SET_STATISTICS",response)
                }
            })
        },
        req_getHistory: function ({ commit },input){
            axios.post(param+"history",input).then(function(response){
                if(response){
                    commit("SET_HISTORY",response)
                }
            })
        }
    },

    mutations: {
        SET_STATISTICS: function (state, data){
            state.statistical = data;
        },
        SET_HISTORY: function (state, data){
            state.history.data = data.data;
            state.history.total = data.last_page;
        }
    }
};
