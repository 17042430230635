<template>
	<div id="app">
		<router-view />
	</div>
</template>

<script>
import { mapMutations, mapState, mapGetters } from "vuex";
export default {
	methods: {
		...mapMutations(["onload", "outload"]),
	},
	computed: {
		...mapState([""]),
		...mapGetters({
			Error: "core/Error",
			Success: "core/Success",
			user: "userinfo/User",
		}),
	},
	mounted() {},
	created() {
        this.$store.dispatch("userinfo/req_getInfo");
		let self = this;
		this.unsubscribe = this.$store.subscribe((mutation) => {
			switch (mutation.type) {
				case "core/SUCCESS_MESSANGE":
					this.$toastr.s(self.Success, this.$t("noti.success"));
					this.outload();
					break;
				case "core/ERROR_MESSANGE":
					this.$toastr.e(self.Error, this.$t("noti.fail"));
					this.outload();
					break;
			}
		});
		const soundst = this.$cookies.get("wx_sound");
		if (soundst !== undefined) {
			this.$store.commit("getSound", soundst == "false" ? false : true);
		}
		const userRaw = this.$cookies.get("wx_info");
		if (userRaw !== undefined && userRaw !== null) {
			this.$store.commit("userinfo/GET_USER_INFO", userRaw);
		}
		const userC = this.$cookies.get("wx_user");
		if (userC !== undefined && userC !== null) {
			this.$store.commit("userinfo/setUser", userC);
		}
	},
	beforeDestroy() {
		this.unsubscribe();
	},
};
</script>