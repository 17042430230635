<template>
  <router-link :to="link || '#'" :class="`navLink btn btn-none ${name} ${isActive ? 'active' : ''}`" @click.native="$emit('click')" >
    <img class="toolIcon" :src="navIcon">
    <div class="toolText" v-if="name != 'profile'">
      {{ $t(`navlink.${name}`) }}
    </div>
  </router-link>
</template>

<script>
export default {
  props: [
    'name',
    'link'
  ],
  data(){
    return {
      isActive: false
    }
  },
  computed: {
    navIcon(){
      var images = require.context('@/assets/images/icons/', false, /\.svg$/)
      return images('./' + this.name + ".svg")
    }
  },
  methods: {
    checkActiveRoute(path){
      this.isActive = path === this.link;
    }
  },
  mounted(){
    this.checkActiveRoute(this.$router.currentRoute.path);
  },
  watch:{
    $route (to){
      this.checkActiveRoute(to.path);
    }
  } 
}
</script>

<style lang="scss" scoped>
/*** IMPORTS ***/
@import '~@/assets/scss/mixins.scss';
@import '~@/assets/scss/colors.scss';

// secondary tools
.navLink {
  margin: 0 4px;

  .toolIcon {
    width: auto;
    height: 28px;
  }

  .toolText {
    font-size: .6em;
    margin-top: 3px;
    font-weight: bold;
  }
}

.navLink.active, .navLink:hover {
  .toolIcon {
    filter: brightness(0) saturate(100%) invert(90%) sepia(79%) saturate(830%) hue-rotate(334deg) brightness(94%) contrast(104%);
  }
  
  .toolText {
    color: $primaryColor;
  }
}
.navLink.profile{
  padding: 0;
  width: 50px!important;
  height:  50px!important;
  margin-top: 5px;
  border: 1px #6abe43 solid!important;
  border-radius: 10px;
}
.navLink.profile img{
  width: 100%;
  height: 100%;
}
.btn{
  padding: 0.375rem 0.5rem
}
</style>