import axios from 'axios';
import Vue from 'vue'
import router from '../../router';
import VueCookies from 'vue-cookies'
import store from '../../store';
var countSend = 0;
var completeSend = 0;
Vue.use(VueCookies)
const token = window.$cookies.get("user_session");
if (token != null && token.key != null) {
    axios.defaults.headers.common["Accept"] = "application/json";
    axios.defaults.headers.common["Authorization"] = "Bearer " + token.key;
}

const instance = axios.create({
    baseURL: process.env.VUE_APP_API,
    ContentType: "application/json",
    Accept: "application/json",
});

instance.interceptors.request.use((config) => {
    if (config.url) {
        store.commit('onload');
        countSend++;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});
instance.interceptors.response.use(
    function (response) {
        store.commit('outload');
        if (response.data.status) {
            if (response.data.message != null && response.data.message != "") {
                store.commit('core/SUCCESS_MESSANGE', response.data.message, { root: true });
            }
            return response.data.data;
        } else {
            store.commit('core/ERROR_MESSANGE', response.data.message, { root: true });
        }

        completeSend--;

    },
    function (error) {
        if (error.response) {
            store.commit('outload');
            switch (error.response.status) {
                case 401:
                    store.commit("logout");
                    router.push("/");
                    break;
            }
        }
    }
);

export default instance;