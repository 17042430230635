const axios = require("../config/axios.config.js").default;
export default {
    namespaced: true,
    state: () => ({
        error: "",
        success:""
    }),

    getters: {
        Error: (state) => {return state.error},
        Success: (state) => {return state.success}
    },

    actions: {
    },

    mutations: {
        SUCCESS_MESSANGE:function(state,messange){
            state.success = messange;
            console.info(messange);
        },
        ERROR_MESSANGE:function(state,messange){
            state.error = messange;
            console.info(messange);
        },
        HiddenChat: function (){},
        ShowChat: function(){}
    }
};
