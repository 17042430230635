const axiosBase = require("../config/axios.config.js").default;
const axios = require('axios');
const api_demo = "https://vs6re1amg7.execute-api.ap-southeast-1.amazonaws.com/demo/api/"
const api_main = "https://y8oi1kuhr0.execute-api.ap-southeast-1.amazonaws.com/api/api/"
export default {
    namespaced: true,
    state: () => ({
        history: {
            open: [],
            close: []
        },
        funds: {
            nonPlayMoney: 0,
            playMoney: 0
        },
        adminHistory: {
            data: [],
            total:0
        }
    }),

    getters: {
        History: (state) => { return state.history },
        Funds: (state) => { return state.funds },
        AdminHistory: (state) => { return state.adminHistory}
    },

    actions: {
        //user
        getHistory: async function ({ commit }, input) {
            try {
                this.isLoadInside = true;
                const response = await axios.get(
                    input.account === 2
                        ? api_main + "betHistory?userId=" + input.user
                        : api_demo + "betHistory?userId=" + input.user
                );
                if (response.data) {
                    commit("HISTORY_TRADE", response.data)
                }
                this.isLoadInside = false;
            } catch (error) {
                console.log(error);
            }
        },
        req_refeshdemo: async function ({ commit }, input) {
            axios.post(api_demo+"Deposit", input).then((response) => {
                if (response) {
                    commit("DEPOSIT_TRADE_DEMO", response);
                }
            })
        },
        req_depositTrade: async function ({ commit }, input) {
            axiosBase.post("wallet/deposit-trade", input).then((response) => {
                if (response) {
                    commit("DEPOSIT_TRADE", response);
                }
            })
        },
        req_withdrawTrade: async function ({ commit }, input) {
            axiosBase.post("wallet/withdraw-trade", input).then((response) => {
                if (response) {
                    commit("WITHDRAW_TRADE", response);
                }
            })
        },
        //admin
        getFunds: async function ({ commit }) {
            axiosBase.get("admin/HostHouse").then(function (response) {
                if (response) {
                    commit("SET_FUNDS", response)
                }
            })
        },
        getFundsV2: async function ({ commit }) {
            axios.get(api_main+"hostHouse").then(function (response) {
                if (response) {
                    commit("SET_FUNDSV2", response.data)
                }
            })
        },
        req_deposit_withdraw_Funds({ dispatch,commit }, input) {
            axiosBase.post("admin/deposit-withdraw", input).then(function (response) {
                if (response) {
                    dispatch("getFundsV2");
                    commit("ACTION_FUNDS_SS");
                }
            })
        },
        req_getAdminHistory: async function({commit},input) {
            axiosBase.get("admin/history-hosthouse?page="+input).then(function (response) {
                if (response) {
                    commit("SET_HISTORY_ADMIN",response);
                }
            })
        }
    },

    mutations: {
        //user
        HISTORY_TRADE: function (state, data) {
            var datanew = data;
            var icon = "";
            var results = 0;

            state.history.open = [];
            state.history.close=  [];
            for (let i = 0; i < datanew.length; i++) {
                //icon down-0,up-1
                if (datanew[i].status == "NEW") {
                    state.history.open.push({
                        'amountBet': datanew[i].amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,"),
                        'timeBet': datanew[i].time,
                        'symbol': datanew[i].symbolId,
                        'icon': datanew[i].betType == "GREEN" ? 1 : 0,
                        'result': results.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")
                    })
                } else {
                    if (datanew[i].betType == "RED") {
                        if (datanew[i].status == "COMPLETE_LOSE") {
                            icon = 1;
                            results = 0 - datanew[i].amount;
                        } else if (datanew[i].status == "COMPLETE_WIN") {
                            icon = 0;
                            results = parseFloat(datanew[i].amount) * 0.95;
                        }
                    }
                    if (datanew[i].betType == "GREEN") {
                        if (datanew[i].status == "COMPLETE_LOSE") {
                            icon = 0;
                            results = 0 - datanew[i].amount;
                        } else if (datanew[i].status == "COMPLETE_WIN") {
                            icon = 1;
                            results = parseFloat(datanew[i].amount) * 0.95;
                        }
                    }
                    state.history.close.push({
                        'amountBet': datanew[i].amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,"),
                        'timeBet': datanew[i].time,
                        'symbol': datanew[i].symbolId,
                        'userBet': datanew[i].betType == "GREEN" ? 1 : 0,
                        'icon': icon,
                        'wl': results > 0 ? 'win' : 'lose',
                        'result': results.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")
                    })
                }


            }

            if (data.clearOpen) {
                state.history.open = [];
            }
            state.history.close = state.history.close.reverse();
            state.history.open = state.history.open.reverse();
        },
        DEPOSIT_TRADE_DEMO: function(state, data){
            this.commit("userinfo/setBalace", {
                demo: true,
                balance: this.state.userinfo.user.accounts[0].balance + 1000,
            });
        },
        DEPOSIT_TRADE: function (state, data) {
            this.commit("userinfo/setBalace", {
                live: true,
                balance: data.tradeBalance,
            });
            this.commit("userinfo/setBalace", {
                main: true,
                balance: data.mainBalance,
            });

        },
        WITHDRAW_TRADE: function (state, data) {
            this.commit("userinfo/setBalace", {
                live: true,
                balance: data.tradeBalance,
            });
            this.commit("userinfo/setBalace", {
                main: true,
                balance: data.mainBalance,
            });

        },
        //admin
        SET_FUNDS: function (state, data) {
            state.funds.nonPlayMoney = data.nonPlayMoney
            state.funds.playMoney = data.playMoney;
        },
        SET_FUNDSV2: function (state, data) {
            state.funds.nonPlayMoney = data.data.nonPlayMoney
            state.funds.playMoney = data.data.playMoney;
        },
        ACTION_FUNDS_SS :function () {},
        SET_HISTORY_ADMIN: function (state, data) {
            state.adminHistory.data = data.data;
            state.adminHistory.total = data.last_page;
        }
    }

};
