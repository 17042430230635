import Vue from 'vue'
import VueRouter from 'vue-router'

// layouts
import Public from '../layouts/Public.vue'
import Private from '../layouts/Private.vue'
// import store from '../store'
// views
import Home from '../views/Home.vue'
import PrivateDashboard from '../views/PrivateDashboard.vue'
import PrivateAdminDashboard from '../views/PrivateAdminDashboard.vue'
import PrivateTransaction_live from '../views/PrivateTransaction_live.vue'
import PrivateTransaction_demo from '../views/PrivateTransaction_demo.vue'
import PrivateAgency from '../views/PrivateAgency.vue'
import PrivateWallet from '../views/PrivateWallet.vue'
import PrivateProfile from '../views/PrivateProfile.vue'
import PrivateSupport from '../views/PrivateSupport.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    component: Public,
    children: [{
        path: '',
        name: 'landing',
        component: Home,
    }],
},
{
    path: '/private',
    component: Private,
    meta: {
        requiresAuth: true,
    },
    children: [{
        path: 'dashboard',
        name: "dashboard",
        component: PrivateDashboard
    },
    {
        path: 'transaction-live',
        name: 'live',
        component: PrivateTransaction_live,
        meta: {
            balance: "live"
        }
    },
    {
        path: 'transaction-demo',
        name: 'demo',
        component: PrivateTransaction_demo,
        meta: {
            balance: "demo"
        }
    },
    // {
    //     path: 'community',
    //     component: PrivateCommunity
    // },
    // {
    //     path: 'lucky',
    //     component: PrivateLucky
    // },
    {
        path: 'agency',
        component: PrivateAgency
    },
    {
        path: 'wallet',
        component: PrivateWallet
    },
    // {
    //     path: 'trade',
    //     component: PrivateTrade
    // },
    // {
    //     path: 'competition',
    //     component: PrivateCompetition
    // },
    {
        path: 'profile',
        component: PrivateProfile
    },
    {
        path: 'support',
        component: PrivateSupport
    },
    // {
    //     path: 'news',
    //     component: PrivateNews
    // },
    {
        path: 'admin-dashboard',
        name: "adminDashboard",
        component: PrivateAdminDashboard
    },
    ]
}
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return {
                selector: to.hash
                // , offset: { x: 0, y: 10 }
            };
        } else if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    }
});
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
};
router.beforeEach(async (to, from, next) => {
    const userRaw = window.$cookies.get("wx_user")
    if (to.matched.some(m => m.meta.requiresAuth)) {
        if (userRaw) {
            next();
        } else {
            next({ name: "landing" });
        }
    } else {
        next();
    }
});
export default router