const axios = require("../config/axios.config.js").default;
export default {
    namespaced: true,
    state: () => ({
        agency: {},
        listAgency: '',
    }),

    getters: {
        Agency: (state) => { return state.agency },
        ListAgency: (state) => { return state.listAgency },
    },

    actions: {
        postBuyAgency: async function({ commit,dispatch }) {
            axios.post("agency/buy").then(function(response) {
                if (response) {
                    commit("BuyAgencySuccess")
                    dispatch("req_getAgency");
                }
            })
        },
        req_getAgency: async function({ commit }) {
            axios.get("agency").then(function(response) {
                commit("GetAgencySuccess", response)
            })
        }
    },

    mutations: {

        BuyAgencySuccess: function() {},
        GetAgencySuccess: function(state, data) {
            state.agency = data;
        }
    }
};