const axios = require("../config/axios.config.js").default;

export default {
    namespaced: true,
    state: () => ({
        userID: '',
        token: '',
        balance: [
            { main: 0 },
            { trade: 0 }
        ],
    }),

    getters: {
    },

    actions: {
        req_PostLogin: function ({ commit, dispatch }, input) {
            axios.post("login", input).then(function (response) {
                if (response) {
                    if (response.require_auth) {
                        commit("REQUIRE_AUTH")
                    }
                    else {
                        commit("LOGIN_SUCCESS", response);
                        dispatch("userinfo/req_getInfo", null, { root: true });
                        // dispatch("UserInfo/req_getBalance",null,{root:true});
                    }
                }
            })
        },
        req_PostRegister: function ({ commit }, input) {
            axios.post("register", input).then(function (response) {
                commit("REGISTER_SUCCESS", response);
            })
        }
    },

    mutations: {
        LOGIN_SUCCESS: function (state, data) {
            state.userID = data.userID;
            state.token = data.token;
            state.balance.main = data.MainBalance;
            state.balance.trade = data.TradeBalance;
            var user = {
                name: "Demo User",
                user: state.userID,
                token: state.token,
                time: [new Date().getHours()+2,new Date().getMinutes()] ,
                walletList: [
                    { id: 1, name: "Main Balance", balance: state.balance.main },
                ],
                accounts: [
                    {
                        id: 1,
                        name: "Tài khoản DEMO",
                        balance: 0,
                    },
                    {
                        id: 2,
                        name: "Tài khoản chính",
                        balance: state.balance.trade,
                    },
                ],
            };
            this.commit("userinfo/setUser", user);
            axios.defaults.headers.common['Authorization'] = "Bearer " + data.Bearer;
            window.$cookies.set("user_session", { key: data.Bearer }, "2H");
            window.$cookies.set("wx_user", JSON.stringify(user), "2H");
        },
        LOGOUT_SUCCESS: function (state, data) {
            this.state.userinfo.user = null;
            state.currentAccountId = null;
            window.$cookies.remove("wx_user");
            window.$cookies.remove("wx_info");
            window.$cookies.remove("user_session")
        },
        REGISTER_SUCCESS: function (state, data) {
         
        },
        REQUIRE_AUTH: function () { }
    }
};
