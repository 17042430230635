<template>
	<div class="privateAgency">
		<!-- MAIN PART -->
		<div class="main" style="padding-left: 20px; padding-right: 20px">
			<b-row>
				<b-col class="right" cols="12" md="5" v-if="!showdetail">
					<div class="card2">
						<h4>Ticket</h4>
						<div class="form-group">
							<label for="">Subject</label>
							<select class="form-control" v-model="ticket.subject">
								<option
									:value="list.ticket_subject_id"
									v-for="list in ListSubject"
									:key="list.id"
								>
									{{ list.ticket_subject_name }}
								</option>
							</select>
						</div>
						<div class="form-group">
							<label for="">Content (*)</label>
							<textarea
								rows="5"
								class="form-control"
								placeholder="Description your problems"
								v-model="ticket.content"
							></textarea>
						</div>

						<div class="form-group">
							<button class="btn btn-success" @click="sendTicket">Send</button>
						</div>
					</div>
				</b-col>
				<b-col v-else class="mx-auto" cols="12" md="5">
					<DetailTicket
						:ID="ticketDetails.id"
						:Subject="ticketDetails.subject"
                        :SubjectID="ticketDetails.SubjectID"
					/>
				</b-col>
				<b-col class="right" cols="12" md="7">
					<div class="card2">
						<div class="tableWrapper">
							<table class="table mb-0">
								<thead>
									<tr>
										<th>Ticket ID</th>
										<th>Subjects</th>
										<th>Status</th>
										<th>Datetime</th>
										<th>Action</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="list in ListTicket" :key="list.ticket_Time">
										<td>{{ list.ticket_ID }}</td>
										<td>{{ list.ticket_subject_name }}</td>
										<td>
											<span
												:style="
													list.ticket_Status == 0
														? 'padding:5px 10px;font-size:14px;background: yellow;color:#000'
														: 'padding:5px 10px;font-size:14px;background: green'
												"
												>{{
													list.ticket_Status == 0 ? "Waiting" : "Replied"
												}}</span
											>
										</td>
										<td>{{ list.ticket_Time }}</td>
										<td>
											<a
												class="btn btn-success btn-sm"
												@click="
													showDetail(list.ticket_ID, list.ticket_subject_name,list.ticket_subject_id)
												"
												>Detail</a
											>
										</td>
									</tr>
									<tr v-if="!ListTicket">
										<td colspan="6" class="text-center">
											{{ $t("trade.nodata") }}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</b-col>
			</b-row>
		</div>
	</div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import DetailTicket from "@/components/DetailTicket.vue";
export default {
	components: {
		DetailTicket,
	},
	data() {
		return {
			inviteCode: "32A1265",
			ticket: {
				content: "",
				subject: "",
				token: "",
			},
			ticketDetails: {
				id: null,
				subject: null,
                SubjectID:null
			},
			showdetail: false,
		};
	},
	computed: {
		registerLink() {
			return `https://wefinex.net/reg?r=${this.inviteCode}`;
		},
		...mapGetters({
			ListSubject: "ticket/ListSubject",
			ListTicket: "ticket/ListTicket",
		}),
	},
	methods: {
		...mapMutations(["onload", "outload"]),
		onCopy: function (e) {
			alert("You just copied: " + e.value);
		},
		sendTicket: async function () {
			this.$store.dispatch("ticket/req_postSubject", this.ticket);
		},
		showDetail(id, subject,SubjectID) {
			this.ticketDetails.id = id;
			this.ticketDetails.subject = subject;
            this.ticketDetails.SubjectID = SubjectID;
			if (id === undefined || id === null) {
				this.$toastr.e(
					"Please Reload Page And Chose Ticket Detail Again",
					"Something Wrong"
				);
				return;
			} else {
				this.showdetail = true;
			}
		},
	},
	created() {
		this.$store.dispatch("ticket/req_getSubject");
		this.$store.dispatch("ticket/req_getTicket");

		let self = this;
		this.unsubscribe = this.$store.subscribe((mutation) => {
			switch (mutation.type) {
				case "core/HiddenChat":
					self.ticketDetails = {
						id: null,
						subject: null,
                        SubjectID: null,
					};
                    self.showdetail =false;
					break;
			}
		});
	},
	beforeDestroy() {
		this.unsubscribe();
	},
	async mounted() {
		// simulate load data
		this.onload();
		await new Promise((resolve) => setTimeout(resolve, 300));
		this.outload();
	},
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/colors.scss";
@import "~@/assets/scss/mixins.scss";

.privateAgency {
	background-image: url("~@/assets/images/bg.png");
	background-size: cover;
	background-position: center center;
	background-attachment: fixed;
	background-repeat: no-repeat;
}
.form-control {
	border: 1px solid $primaryColor;
}
.table thead th {
	vertical-align: middle;
	border-bottom: 1px solid $primaryColor;
	border-top: 1px solid $primaryColor;
}
.main {
	min-height: calc(100vh - 100px);
	padding: 50px 0;

	.left {
		margin-bottom: 30px;

		.title {
			font-size: 2em;
			font-weight: bold;
			margin-bottom: 10px;
		}

		.button {
			font-size: 1.5em;
			font-weight: bold;
		}
	}

	.right {
		.card2 {
			background-color: rgba(0, 0, 0, 0.3);
			border: 1px solid $primaryColor;
			border-radius: 20px;
			padding: 30px 20px;
		}
		.inputGroup {
			border: 1px solid $primaryColor;
			border-radius: 5px;
			padding: 5px;
			display: flex;
			overflow: hidden;

			input {
				border: none;
				background-color: transparent;
				color: white;
				width: calc(100% - 80px);
			}

			button {
				width: 80px;
				border-radius: 5px;
			}

			input:active,
			input:focus {
				border: none;
				outline: none;
			}
		}
	}
}

.footer {
	background-color: #2b2f42;
	padding: 20px 0;

	.box {
		margin: 20px 0;

		.icon {
			border: 1px solid $primaryColor;
			width: 60px;
			height: 60px;
			padding: 10px;
			border-radius: 30px;
			overflow: hidden;

			img {
				width: 100%;
				height: 100%;
			}
		}

		.info {
			width: calc(100% - 80px);
			margin-left: 10px;

			.name {
				font-size: 1.1em;
				font-weight: bold;
				text-transform: uppercase;
			}
		}
	}
}

/*** RESPONSIVE ON PC ***/
@media (min-width: 992px) {
	.main {
		.left {
			.title {
				font-size: 2.5em;
			}
		}
	}
}
</style>